import { Helmet } from "react-helmet-async";

import personAvatar from "../../assets/Icons/personAvatar.svg";
import amadelli from "../../assets/Images/amadelli.png";
import grupo_the_best from "../../assets/Images/grupo_the_best.png";

import { Box } from "../../components/Box";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { BannerHome } from "../../components/Banners";

import { images_work } from "./utils/images";

import { Boxes, ButtonGupy, Container, ContentButton } from "./styles";

export const TrabalheConosco = () => {
  const Lealdade = require("../../assets/Images/Icones_trabalhe_conosco/Lealdade.webp");
  const Comprometimento = require("../../assets/Images/Icones_trabalhe_conosco/Comprometimento.webp");
  const Humildade = require("../../assets/Images/Icones_trabalhe_conosco/Humildade.webp");

  const items = [
    {
      img: Lealdade,
      alt: "ícone de um indivíduo subindo no pódio",
      text: "Lealdade",
      title: "Lealdade",
      back: "A lealdade é o nosso principal pilar entre nosso time e nossos parceiros para crescermos juntos!",
      key: 1,
    },
    {
      img: Comprometimento,
      alt: "ícone de um pódio",
      text: "Comprometimento",
      title: "Comprometimento",
      back: "O comprometimento é o que faz estarmos de corpo e alma pelas pessoas e pelo negócio, é o que amamos fazer!",
      key: 2,
    },
    {
      img: Humildade,
      alt: "ícone do coletivismo que contém três indivíduos desenhados",
      text: "Humildade",
      title: "Humildade",
      back: "Abrir-se para ouvir e aprender, sempre que necessário, nos conduz a um crescimento constante e fortalece nossas conexões!",
      key: 2,
    },
  ];

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Trabalhe conosco</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/trabalhe-conosco`}
        />
        <meta property="og:image" content={images_work.banner_work} />
        <meta name="twitter:image" content={images_work.banner_work} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Navbar />
      <BannerHome
        img={images_work.banner_work}
        alt="Banner da página Trabalhe conosco. Na foto, imagens de trabalhadores da rede The Best"
        text={false}
        imgMobile={images_work.banner_work}
      />
      <Container>
        <h1>
          Quer <span className="span-title">trabalhar </span>conosco?
        </h1>
        <p>
          O <b>Grupo The Best</b> é composto por marcas renomadas, como a{" "}
          <b>The Best Açaí</b>, a maior rede de sorvetes e açaís do mundo, e a{" "}
          <b>Amadelli</b>, localizada em Londrina-PR. A <b>Amadelli</b> é uma
          fábrica que distribui 40 toneladas diárias de açaí e sorvete. Com uma
          área de 12.000 m² e tecnologia de ponta, a empresa assegura a
          qualidade e o sabor incomparável dos produtos nas lojas{" "}
          <b>The Best Açaí</b>. Clique no botão abaixo para ver as vagas
          disponíveis e venha fazer parte do nosso time.
        </p>
        <ContentButton>
          <ButtonGupy
            target="_blank"
            rel="noreferrer"
            href="https://app.zazos.com/t/grupo-the-best/p/2123b6ca-94ee-4ecd-bae1-816eadd386ab"
          >
            Veja nossas vagas
          </ButtonGupy>
          <div>
            <img src={grupo_the_best}></img>
            <img src={amadelli}></img>
          </div>
        </ContentButton>

        <h1>
          Nossa <span className="span-title">cultura</span>
        </h1>
        <Boxes>
          {items.map((box) => (
            <Box
              img={box.img ? box.img : personAvatar}
              alt={box.alt}
              text={box.text}
              back={box.back}
              title={box.title}
            />
          ))}
        </Boxes>
        <h1>
          Faça o seu <span className="span-title">melhor</span>
        </h1>
        <p>
          <b>Esse é nosso lema</b>
        </p>
        <p>
          Acreditamos profundamente em nossos valores, tendo em mente a
          importância de nossa própria cultura. A empresa busca a diversidade,
          inclusão, agilidade e excelência. Nosso ambiente é múltiplo e
          respeitamos as pessoas como elas são, promovendo a importância do
          trabalho em equipe e ajudando a construir cada degrau dessa jornada.
        </p>
      </Container>
      <Footer />
    </>
  );
};
