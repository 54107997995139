import principle_1 from "../../../assets/svg/principle_1.svg";
import principle_2 from "../../../assets/svg/principle_2.svg";
import principle_3 from "../../../assets/svg/principle_3.svg";
import principle_4 from "../../../assets/svg/principle_4.svg";
import principle_5 from "../../../assets/svg/principle_5.svg";
import principle_6 from "../../../assets/svg/principle_6.svg";
import principle_7 from "../../../assets/svg/principle_7.svg";
import principle_8 from "../../../assets/svg/principle_8.svg";
import principle_9 from "../../../assets/svg/principle_9.svg";

export const principlesOdd = [
  {
    id: 1,
    icon: principle_1,
    title: "Faça o seu melhor",
    text: "Em todas as ações do seu dia, e em tudo o que você faz, você se pergunta: estou fazendo o meu melhor? Seja com amigos, família, religião e principalmente trabalho, se cobre e faça o seu melhor! É assim que você se desenvolve! Somos uma empresa que estamos em melhoria contínua e mudando sempre, mas uma coisa que nunca irá mudar é que sempre vamos fazer o nosso melhor! Progresso = disciplina + trabalho duro!",
    alt: "Faça o seu melhor",
  },

  {
    id: 2,
    icon: principle_2,
    title: "Vencer não é para todo mundo",
    text: "Vencer é um caminho cheio de desafios, e nem todos estão prontos para essa jornada. O verdadeiro vencedor aprende com os erros e assume a responsabilidade por suas ações, evitando culpar os outros. A procrastinação é um obstáculo que impede a realização de sonhos. Portanto, é essencial agir com determinação: planeje, organize e execute. Vencer é para aqueles dispostos a enfrentar desafios e se reinventar.",
    alt: "Vencer não é para todo mundo",
  },
  {
    id: 3,
    icon: principle_3,
    title: "Falou, cumpriu!",
    text: "Responsabilidade e transparência. Sua palavra deve valer mais do que qualquer contrato ou documento. Seja leal à sua palavra e valorize o tempo do outro. Dê prioridade ao que importa, não deixe seu tempo ao léu para que os outros decidam.",
    alt: "Falou, cumpriu!",
  },

  {
    id: 4,
    icon: principle_4,
    title: "Não seja desalinhado",
    text: "Agir sem entender o propósito das suas ações é como remar contra a corrente enquanto toda a equipe avança em direção oposta. A comunicação é nossa ferramenta mais poderosa para garantir que todos estejam alinhados. Utilize-a a seu favor: pergunte, questione, busque entender e melhore sempre que possível. Certifique-se de que todos na sua equipe compartilham a mesma visão. Lembre-se: se um membro da equipe não estiver em sintonia, o esforço coletivo pode se perder, levando todos a um destino incerto.",
    alt: "Não seja desalinhado",
  },
  {
    id: 5,
    icon: principle_5,
    title: "Inovação não é escolha, é necessidade",
    text: "Inovar é estar aberto ao novo e acompanhar as mudanças rápidas. Mais do que mudar, é preciso impressionar, escutando ideias e usando a criatividade para crescer. A inovação é um processo contínuo que envolve todos, e quanto mais inovamos, melhores nos tornamos.",
    alt: "Inovação não é escolha, é necessidade",
  },

  {
    id: 6,
    icon: principle_6,
    title: "Talento vence jogos, mas só o trabalho em equipe ganha campeonatos",
    text: "Cada um de nós possui talentos e habilidades únicas, mas é a união dessas forças que nos transforma em um time campeão. O talento individual pode garantir vitórias isoladas, mas é o trabalho em equipe, onde todos colaboram, somam e se apoiam, que constrói grandes conquistas. Quando combinamos nossas competências, o impacto vai além do que qualquer um poderia alcançar sozinho.",
    alt: "Talento vence jogos, mas só o trabalho em equipe ganha campeonatos",
  },
  {
    id: 7,
    icon: principle_7,
    title: "Quem falha em planejar, planeja falhar",
    text: "Planejar vai além de organizar tarefas; é estar preparado para qualquer cenário e garantir que tudo aconteça no tempo certo. Sem planejamento, o improviso domina e os erros aumentam. Um bom plano permite decisões rápidas, soluções eficientes e cumprimento de prazos. Quem planeja tem o controle, mesmo em meio ao caos, enquanto a falta de planejamento é o primeiro passo para o fracasso.",
    alt: "Quem falha em planejar, planeja falhar",
  },

  {
    id: 8,
    icon: principle_8,
    title: "Professor x aluno",
    text: "Em toda conversa dentro da empresa, pergunte-se naquele momento se você é um professor ou aluno no assunto. Saiba se posicionar e respeitar as posições de conhecimento. Tenha atitude para mostrar aquilo que você domina, mas também tenha humildade para ouvir e aprender sempre que necessário.",
    alt: "Professor x aluno",
  },
  {
    id: 9,
    icon: principle_9,
    title: "Não seja permissivo!",
    text: "Nada adianta tolerarmos atitudes desalinhadas com nossa cultura. Ignorar problemas ou aceitar injustiças não nos torna campeões. Para sermos os melhores, precisamos de pessoas com caráter, ética e justiça. Verdadeiras lideranças fazem o que é certo, mesmo que isso exija sacrifícios.",
    alt: "Não seja permissivo!",
  },
];
