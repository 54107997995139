import styled from "styled-components";
import { NavLink } from "react-router-dom";

interface IMobileProps {
  open: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 5rem;
  z-index: 999;
`;

export const Nav = styled.nav`
  background: var(--black);
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 6.8rem;
  z-index: 999;

  @media screen and (max-width: 1600px) {
    padding: 0 120px;
    font-size: 1rem;
  }

  @media screen and (max-width: 1400px) {
    padding: 0 50px;
    font-size: 0.9rem;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  height: 2.5rem;

  @media screen and (max-width: 1600px) {
    height: 35%;
  }

  @media screen and (max-width: 800px) {
    height: 40%;
  }
`;

export const ListOfLinks = styled.ul<IMobileProps>`
  display: flex;
  align-items: flex-end;
  list-style: none;
  text-align: center;
  justify-content: space-between;
  overflow: hidden;

  .buttonFranchisee {
    font-weight: 700;
    font-size: 1.25rem;

    color: var(--black);
    text-decoration: none;

    background: var(--primary);

    padding: 0.5rem 2rem;
    margin-left: 2rem;
    border-radius: 1rem;
    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }
    :hover {
      animation: pulse 1s infinite;
      transition: 0.3s;
    }
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      70% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: ${({ open }) => (open ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: var(--black);
  }
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;

  color: var(--white);

  border-bottom: 2px solid transparent;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    width: 100%;

    &:hover {
      border: none;
    }
  }
`;

export const NavLinks = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  margin-left: 40px;
  color: var(--white);
  transition: all ease 0.5s;

  &:first-child {
    margin-left: 0;
  }

  .active {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    height: 5.1rem;
    position: relative;
    color: var(--primary);
    letter-spacing: 0rem;

    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 6px;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      background-color: var(--primary);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  .inactive {
    text-decoration: none;
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;

    padding: 2rem;
    width: 100%;
    margin-left: 0;

    &:hover {
      color: var(--primary);
      transition: all 0.3 ease;
    }
    .active {
      height: 2rem;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const SocialMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 10rem;
  right: 2rem;
  height: 12rem;
  width: 1.73rem;
  z-index: 10;
`;

export const SocialIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
