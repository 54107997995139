import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Helmet } from "react-helmet-async";
import Carousel from "react-elastic-carousel";

import { images_home, images_toda_ocasiao } from "./utils/images";

import Bonde from "../../assets/svg/bonde.svg";
import G1 from "../../assets/Images/imprensa_g1.png";
import location from "../../assets/Icons/location.svg";
import Tribuna from "../../assets/svg/tribuna_logo.svg";
import BtgPactual from "../../assets/svg/btgpactual.svg";
import personAvatar from "../../assets/Icons/personAvatar.svg";
import FolhaLondrina from "../../assets/svg/folhalondrina.svg";
import IstoEDinheiro from "../../assets/svg/istoedinheiro.svg";
import club_the_best from "../../assets/Images/club_the_best.png";

import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { CustomModal } from "../../components/Modal";
import { BannerHome } from "../../components/Banners";
import { Testimonial } from "../../components/Testimonial";

import { useModal } from "../../hooks/useModal";

import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndLowerCase";

import {
  ButtonLocation,
  ContentAbout,
  ContentInput,
  ContentStickers,
  AboutApp,
  FindStoreSubtitle,
  FindStoreTitle,
  InputFind,
  Title,
  Stickers,
  ContentStickerLeft,
  ContentGalery,
  Images,
  Icon,
  ContentSwiper,
  ContentTestimonial,
  ContainerSwiper,
  Container,
  ContainerSearch,
  ContentNewPapper,
  Materias,
  SeloDesktop,
  SeloMobile,
  BannerSelo,
  ContentAppAbout,
  ClubApp,
  ImageItem,
  ContentLinks,
} from "./styles";

interface ITestimonialProps {
  title: string;
  body: React.ReactNode;
  avatar?: any;
  name: string;
  store: string;
  alt?: string;
  key: number;
}

export const Home = () => {
  const navigate = useNavigate();
  const [locationSearch, setLocationSearch] = useState<string>("");

  const AndroidLink = require("../../assets/Images/android.webp");
  const AppleLink = require("../../assets/Images/apple.webp");

  //imprensa
  const Forbes = require("../../assets/Images/imprensa1.webp");
  const SuaFranquia = require("../../assets/Images/imprensa2.webp");
  const Gazeta = require("../../assets/Images/imprensa3.webp");
  const ComVC = require("../../assets/Images/imprensa4.webp");
  const Exame = require("../../assets/Images/logo_exame.webp");
  const MelhoresFranquiasMobile = require("../../assets/Images/melhores-franquias-vertical.webp");

  //franqueados
  const Jairo = require("../../assets/Images/FranqImgs/jairo_e_esposa.webp");
  const Jair = require("../../assets/Images/FranqImgs/franqueado_majolo.webp");
  const Rafael = require("../../assets/Images/FranqImgs/rafael.webp");
  const Vera = require("../../assets/Images/FranqImgs/vera_brusque.webp");
  const Saimon = require("../../assets/Images/FranqImgs/saimon_blumenau.webp");

  const [modalMore, setModalMore] = useModal();
  const [showMore, setShowMore] = useState<ITestimonialProps>();

  const getLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json");
      const { city, region_code } = response.data;
      if (city && region_code) {
        navigate(`/lojas`, {
          state: {
            cityLocation: removeAccentsAndLowerCase(city),
            stateLocation: removeAccentsAndLowerCase(region_code),
          },
        });
      } else {
        alert("Não foi possível identificar sua cidade e estado.");
      }
    } catch (error) {
      console.error("Erro ao obter localização:", error);
      alert("Não foi possível obter sua localização.");
    }
  };

  const items = [
    {
      avatar: Rafael,
      title: "Depoimento de Franqueado",
      body: "De ex-funcionário da Franqueadora eu me tornei Franqueado. Essa foi a minha trajetória dentro do The Best e posso dizer que foi um privilégio que poucos tiveram! Isto pois, antes de assinar o contrato eu tive a oportunidade de conhecer a realidade, o “backstage” o dia a dia da empresa toda e essa experiência me fez ter absoluta certeza que eu deveria investir nessa marca, pois sabia que havia uma equipe F*da de verdade trabalhando por mim para construir e fazer coisas que muitos diriam impossível. Com a benção de Deus, trabalhei muito, juntei dinheiro, abri a primeira loja e deu certo! Hoje tenho 3 lojas e pretendo abrir mais.",
      name: "Rafael Roberto Gonzalez",
      store: "Franqueado de 3 unidades (PR)",
      alt: "Imagem de um franqueado da rede The Best",
      key: 1,
    },
    {
      avatar: Jair,
      title: "Depoimento de Franqueado",
      body: "Eu tinha uma casa de shows, a pandemia veio e me faliu completamente. Achei na the Best Açaí não só algo que me reestruturou financeiramente, mas sim um ramo que me dá prazer em trabalhar. Hoje possuo 5 lojas e ainda pretendo expandir.",
      name: "Jair Majolo Filho",
      store: "Franqueado de 6 unidades (PR, SC)",
      alt: "Imagem de um franqueado da rede The Best",
      key: 2,
    },
    {
      avatar: Jairo,
      title: "Depoimento de Franqueado",
      body: "Eu e minha esposa Aline tínhamos vontade de abrir uma empresa, de preferência uma Franquia, para ter uma renda extra, mas não tínhamos confiança nas marcas que tinham disponíveis, ou o dinheiro necessário, porque marcas fortes até então custavam muito caro para abrir. Conhecemos a The Best açaí e junto com nossos familiares e amigos viramos clientes fiéis. Depois de um tempo juntando dinheiro resolvemos abrir a primeira loja, na cidade em que meu irmão mora, graças a Deus, ao nosso trabalho e a equipe toda da The Best açaí hoje estamos com 6 lojas, uma sociedade entre nossa família que deu certo, e nos uniu ainda mais. Hoje ela não é uma renda extra, mas sim nossa principal fonte de renda.",
      name: "Jairo Roberto de Almeida e Aline de Almeida",
      store: "Franqueado de 5 unidades (SC, MS, PR)",
      alt: "Imagem de um franqueado da rede The Best",
      key: 3,
    },
    {
      avatar: Vera,
      title: "Depoimento de Franqueado",
      body: `Prestes a completar 60 anos, me sinto presenteada com mais uma unidade em Brusque, Santa Catarina. Este amor pela franquia começou em 2017, quando fui convidada pelo meu filho Bruno a empreender - hoje ele trabalha ao meu lado como sócio no negócio. Quando conheci a The Best Açaí não sabia que mudaria a minha vida para sempre. Desejávamos vir para Santa Catarina e foram quatro anos de relacionamento para que eu entrasse no grupo. Ao longo desse tempo eu acompanhava de perto o crescimento e a história da marca e dos empreendedores.
      Quando decidi vir para Brusque, em 2021, abrir a primeira unidade, largamos tudo, inclusive a família e, em dois carros, com o que dava para trazer, chegamos eu meu marido. Meu filho veio logo após e hoje digo que foi a minha melhor escolha. 
      O que mudou? Tudo, exatamente tudo! Minha franquia é o meu maior desafio e também o meu maior orgulho.`,
      name: "Vera Lúcia Rubbo",
      store: "Franqueada de 2 unidades (SC)",
      alt: "Imagem de um franqueado da rede The Best",
      key: 4,
    },
    {
      avatar: Saimon,
      title: "Depoimento de Franqueado",
      body: `Minha esposa era cliente das lojas da The Best Açaí no Paraná e sempre se encantou com a qualidade dos produtos e o atendimento das lojas. Quando decidimos empreender, foi a primeira marca que nos veio em mente e que visitamos com o olhar voltado aos negócios. Hoje posso dizer que a The Best Açaí mudou nossas vidas. Temos muito orgulho de fazer parte dessa história que envolve muita dedicação, trabalho, empreendedorismo e olhar voltado para os clientes. Estamos abrindo em 2023 nossa quinta loja em Santa Catarina e, assim como a rede, continuaremos crescendo juntos.`,
      name: "Saimon Porto Kraus",
      store: "Franqueado de 3 unidades (SC)",
      alt: "Imagem de um franqueado da rede The Best",
      key: 5,
    },
  ];

  const openModal = (item: ITestimonialProps) => {
    setShowMore(item);
    setModalMore(true);
  };

  return (
    <div>
      <Helmet>
        <title>The Best Açaí - Início</title>
        <link rel="canonical" href={`https://thebestacai.com.br`} />
        <meta property="og:image" content={images_home.banner_home} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Navbar />

      <BannerHome
        img={images_home.banner_home}
        alt="Banner da página inicial escrito deu vontade, né? aqui você monta do seu jeito. Com uma modelo segurando um copo com sorvete The Best."
        text={true}
        imgMobile={images_home.banner_home_mobile}
      />

      <Container>
        <ContainerSearch>
          <FindStoreTitle>
            Descubra a <span>The Best Açaí</span> mais perto de você!
          </FindStoreTitle>
          <FindStoreSubtitle>
            Lembre-se de permitir acesso à sua localização
          </FindStoreSubtitle>

          <ContentInput>
            <InputFind
              placeholder={"Digite aqui"}
              onChange={(event) =>
                setLocationSearch(
                  removeAccentsAndLowerCase(event?.target?.value)
                )
              }
              onKeyDown={(event) =>
                event.key === "Enter" &&
                navigate(`/lojas`, {
                  state: { locationSearch: locationSearch },
                })
              }
            />
            <Icon
              size={35}
              onClick={() =>
                navigate(`/lojas`, {
                  state: { locationSearch: locationSearch },
                })
              }
            />
            <ButtonLocation onClick={() => getLocation()}>
              <img src={location} />
              Minha localização
            </ButtonLocation>
          </ContentInput>
        </ContainerSearch>

        <ContentAbout>
          <ContentStickers>
            <ContentStickerLeft>
              <Title>
                A <span>maior</span> rede de self-service de açaí do{" "}
                <span>mundo!</span>
              </Title>
              <p>
                Com 12 sabores de açaí, 24 sabores de sorvete e mais de 40
                acompanhamentos disponíveis no buffet, proporcionamos a
                experiência de montar um copo do seu jeito, com liberdade de
                experimentação entre inúmeras combinações. Possuímos receitas
                próprias, desenvolvidas através de uma busca por inovação que
                nos motiva a oferecer os melhores sabores, cores, texturas e
                processos.
              </p>
            </ContentStickerLeft>
            <Stickers
              src={images_home.glasses_home}
              alt="Copos de açaí da marca"
            />
          </ContentStickers>

          <AboutApp img={images_home.banner2_home}>
            <ContentAppAbout>
              <p>
                Acumulando pontos no
                <ClubApp src={club_the_best} alt="Club The Best Logo" />, você
                ganha mais açaí e prêmios exclusivos
              </p>
              <br />
              <p className="span-bold">Baixe agora mesmo!</p>
              <ContentLinks>
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.amatech.thebestclubapp"
                    target="_blank"
                    rel="noreferrer"
                    aria-hidden="true"
                  >
                    <img
                      src={AndroidLink}
                      alt="Link app googleplay"
                      aria-hidden="true"
                    />
                  </a>{" "}
                  <a
                    href="https://apps.apple.com/br/app/clube-the-best/id6463561926"
                    target="_blank"
                    rel="noreferrer"
                    aria-hidden="true"
                  >
                    <img
                      src={AppleLink}
                      alt="Link app apple store"
                      aria-hidden="true"
                    />
                  </a>
                </div>
              </ContentLinks>
            </ContentAppAbout>
          </AboutApp>

          <ContentGalery>
            <h3>
              <span className="primary">#</span> Toda ocasião merece um{" "}
              <span className="primary">The Best</span>
            </h3>
            <Images>
              {images_toda_ocasiao.map((_image, index) => (
                <ImageItem key={index} src={_image.url} alt="# Toda Ocasião" />
              ))}
            </Images>
          </ContentGalery>
        </ContentAbout>

        <ContentNewPapper>
          <h3>Newspaper e imprensa</h3>

          <Materias>
            <a
              href="https://forbes.com.br/forbes-money/2023/02/the-best-acai-vai-abrir-100-lojas-em-2023-e-preve-faturar-r-350-milhoes/"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img src={Forbes} alt="Matéria jornalistica da Forbes" />
            </a>
            <a
              href="https://www.suafranquia.com/artigos/gurus/artigo-o-que-esperar-do-mercado-de-franquias-em-2023/"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img
                className="sua-franquia"
                src={SuaFranquia}
                alt="Matéria jornalistica da Sua Franquia"
                aria-hidden="true"
              />
            </a>
            <a
              href="https://comvcportal.com.br/noticia/54592/rede-paranaense-de-acai-e-sorvetes-self-service-pretende-faturar-350-milhoes-em-2023"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img src={ComVC} alt="Matéria jornalistica do ComVC" />
            </a>
            <a
              href="https://www.folhadelondrina.com.br/economia/empresa-de-londrina-fatura-r-215-mi-em-2022-e-se-destaca-em-franquias-3229560e.html?d=1"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img
                src={FolhaLondrina}
                alt="Matéria jornalistica do Folha Londrina"
              />
            </a>
            <a
              href="https://revistapegn.globo.com/franquias/noticia/2023/03/amigos-trocam-engenharia-por-self-service-de-acai-e-faturam-r-215-milhoes-com-franquias.ghtml"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img src={G1} alt="Matéria jornalistica do G1" />
            </a>

            <a
              href="https://www.tribunapr.com.br/noticias/curitiba-regiao/rede-focada-em-acai-abre-uma-loja-a-cada-42-horas-no-brasil-curitiba-deve-ganhar-14-em-2023/"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img src={Tribuna} alt="Matéria jornalistica do Tribuna" />
            </a>
            <a
              href="https://www.gazetadopovo.com.br/vozes/parana-sa/rede-paranaense-the-best-acai-projeta-faturar-r-350-milhoes-em-2023/"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img src={Gazeta} alt="Matéria jornalistica da Gazeta do Povo" />
            </a>
            <a
              href="https://www.bonde.com.br/bondenews/economia/empresa-de-londrina-fatura-r-215-mi-em-2022-e-se-destaca-no-ramo-de-franquias"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img src={Bonde} alt="Matéria jornalistica do Bonde" />
            </a>
            <a
              href="https://content.btgpactual.com/research/home/macro-e-estrategia/noticias/650709db1c92ce6a952e14f1/Franquia-dos-engenheiros-do-acai-chega-a-300-lojas-e-vende-R-2-milhoes-em-24h"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img
                className="btg"
                src={BtgPactual}
                alt="Matéria jornalistica da BTG Pactual"
              />
            </a>
            <a
              href="https://exame.com/negocios/franquia-dos-engenheiros-do-acai-chega-a-300-lojas-e-vende-r-2-milhoes-em-24h/"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img src={Exame} alt="Matéria jornalistica da Exame" />
            </a>
            <a
              href="https://istoedinheiro.com.br/franquia-de-acai-quadruplica-faturamento/"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img
                className="istoEDinheiro"
                src={IstoEDinheiro}
                alt="Matéria jornalistica da BTG Pactual"
              />
            </a>
          </Materias>
          <BannerSelo>
            <a
              href="https://revistapegn.globo.com/melhores-franquias/noticia/2024/06/conheca-as-franquias-5-estrelas-do-premio-as-melhores-franquias-do-brasil-2024.ghtml"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <SeloDesktop
                src={images_home.banner3_home}
                alt="Melhores Franquias"
              />
              <SeloMobile
                src={MelhoresFranquiasMobile}
                alt="Melhores Franquias"
              />
            </a>
          </BannerSelo>
        </ContentNewPapper>

        <ContainerSwiper>
          <h3>Depoimentos dos franqueados</h3>
        </ContainerSwiper>

        <ContentSwiper>
          <ContentTestimonial>
            {/* @ts-ignore */}
            <Carousel
              showArrows={false}
              enableAutoPlay={false}
              autoPlaySpeed={4000}
              itemsToShow={
                window.innerWidth > 1350 ? 3 : window.innerWidth > 1024 ? 2 : 1
              }
            >
              {items.map((item) => (
                <Testimonial
                  avatar={item.avatar ? item.avatar : personAvatar}
                  title={item.title}
                  body={
                    <>
                      {item?.body.length < 160 ? (
                        item.body
                      ) : (
                        <>
                          {item.body.slice(0, 160)}
                          <span>...</span>
                          <span
                            className="moreTestimonial"
                            onClick={() => openModal(item)}
                          >
                            Ver mais
                          </span>
                        </>
                      )}
                    </>
                  }
                  name={item.name}
                  store={item.store}
                  alt={item.alt}
                  key={item.key}
                />
              ))}
            </Carousel>
          </ContentTestimonial>
        </ContentSwiper>

        <CustomModal
          title={showMore?.title}
          handleClose={setModalMore}
          isActive={modalMore}
          key={showMore?.key}
          footer={
            <div
              className="content_bottom"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={showMore?.avatar ? showMore.avatar : personAvatar}
                alt="avatar"
                style={{ borderRadius: "50%", marginRight: "1rem" }}
              />
              <span>{showMore?.name}</span>
            </div>
          }
        >
          <p>{showMore?.body}</p>
        </CustomModal>
      </Container>

      <Footer active={true} />
    </div>
  );
};
