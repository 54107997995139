export enum images_home {
  banner_home = "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/banner_home.webp/beautify",
  glasses_home = "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/glasses_home.png/beautify",
  banner2_home = "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/banner2_home.png/beautify",
  banner3_home = "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/banner3_home.png/beautify",
  banner_home_mobile = "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/banner_home_mobile.webp/beautify",
}

export const images_toda_ocasiao = [
  {
    id: 1,
    name: "toda_ocasiao_1",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_1.png/beautify",
  },
  {
    id: 2,
    name: "toda_ocasiao_2",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_2.png/beautify",
  },
  {
    id: 3,
    name: "toda_ocasiao_3",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_3.png/beautify",
  },
  {
    id: 4,
    name: "toda_ocasiao_4",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_4.png/beautify",
  },
  {
    id: 5,
    name: "toda_ocasiao_5",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_5.png/beautify",
  },
  {
    id: 6,
    name: "toda_ocasiao_6",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_6.png/beautify",
  },
  {
    id: 7,
    name: "toda_ocasiao_7",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_7.png/beautify",
  },
  {
    id: 8,
    name: "toda_ocasiao_8",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_8.png/beautify",
  },
  {
    id: 9,
    name: "toda_ocasiao_9",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_9.png/beautify",
  },
  {
    id: 10,
    name: "toda_ocasiao_10",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_10.png/beautify",
  },
  {
    id: 11,
    name: "toda_ocasiao_11",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_11.png/beautify",
  },
  {
    id: 12,
    name: "toda_ocasiao_12",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_12.png/beautify",
  },
  {
    id: 13,
    name: "toda_ocasiao_13",
    url: "https://amatech-prd.azure-api.net/api/janus/files-management/ti/website/toda_ocasiao_13.png/beautify",
  },
];
