import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 3.125rem 16.25rem;
  flex-direction: column;
  width: 100%;

  h1 {
    font-family: "Visby-bold";
    font-size: 3rem;

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
      align-items: flex-start;
      margin-bottom: 0;
    }
    @media screen and (max-width: 500px) {
      font-size: 1.4rem;
    }
  }

  .span-title {
    font-family: "Visby-bold";
    color: var(--primary);
  }

  p {
    color: var(--gray-100);
    font-family: "Visby-light";
    font-size: 1.5rem;
    margin-bottom: 0;
    letter-spacing: 0;
    line-height: 2.5rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      margin-top: 1rem;
      text-align: center;
    }
  }
  b {
    font-family: "Visby-bold";
  }

  @media screen and (max-width: 1500px) {
    padding: 3rem 13rem;
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 10rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 3rem 8rem;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 3rem;
  }
  @media screen and (max-width: 575px) {
    padding: 1.5rem 2rem 1rem;
  }
  @media screen and (max-width: 420px) {
    padding: 1.5rem 1rem 1rem;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  width: 100%;
  margin: 2rem 0;

  div {
    display: flex;
    justify-content: space-between;
    width: 40%;

    img {
      margin: 1rem;
    }

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Boxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 2.5rem 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 0;
  }
`;

export const ButtonGupy = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  width: 28rem;
  height: 3.4rem;
  color: var(--black);
  border: 0;
  border-radius: 2rem;
  font-family: "Visby-bold";
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  :hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;
