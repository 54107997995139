import styled from "styled-components";

export const ContainerImg = styled.div`
  display: flex;
  position: relative;
  justify-content: start;
  width: 100%;
  height: 80vh;
  overflow: hidden;
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  @media screen and (max-width: 575px) {
    display: none;
  }
`;

export const ImgMobile = styled.img`
  display: none;
  @media screen and (max-width: 575px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    display: block;
  }
`;

export const ContentLinks = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 50%;
  height: 100%;
  bottom: 15%;

  div {
    display: flex;
    gap: 2rem;
    @media only screen and (max-width: 1024px) {
      gap: 1rem;
    }
  }

  a {
    height: 4.3rem;
    width: 13.4rem;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1750px) {
    width: 55%;
    bottom: 10%;
  }
  @media only screen and (max-width: 1500px) {
    width: 80%;
  }
  @media only screen and (max-width: 1300px) {
    width: 90%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 575px) {
    display: none;
  }
`;
