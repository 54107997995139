import React from "react";

import footer from "../../assets/svg/footer.svg";
import seloabffooter from "../../assets/Images/seloabffooter.png";
import selopegn2024 from "../../assets/Images/selopegn2024.png";
import seloabf2025 from "../../assets/Images/seloabf2025.png";
import seloempresaamiga from "../../assets/Images/seloempresaamiga.png";
import selopequenoprincipe from "../../assets/Images/selopequenoprincipe.png";
import logofooter from "../../assets/svg/logofooter.svg";

import {
  ButtonBox,
  ButtonBoxButton,
  Container,
  FooterContainer,
  Imprensa,
  Line,
  Regulamentation,
  Selo,
  SpanCnpj,
  Content,
  InfoContent,
} from "./styles";

interface IFooter {
  active?: boolean;
}

export const Footer: React.FC<IFooter> = ({ active }) => {
  return (
    <Container>
      <ButtonBox active={active}>
        <ButtonBoxButton>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://franquia.thebestacai.com.br/sejaumfranqueado"
          >
            Seja um Franqueado
          </a>
        </ButtonBoxButton>
      </ButtonBox>
      <FooterContainer img={footer}>
        <Content>
          <InfoContent>
            <div>
              <Imprensa>
                <span>Assessoria de Imprensa </span>
                <span>Julia Nascimento</span>
                <span>comunicacao@thebestacai.com.br</span>
              </Imprensa>
              <br />
              <SpanCnpj>CNPJ 28.976.949/0001-84</SpanCnpj>
              <br />
              <SpanCnpj>GRUPO THE BEST FRANCHISING LTDA.</SpanCnpj>
            </div>
            <div>
              <a
                href="https://franquias.portaldofranchising.com.br/franquia-the-best-acai/"
                target="blank"
                className="img"
              >
                <Selo src={seloabf2025} alt="Selo ABF 2025" />
              </a>
              <a
                href="https://franquias.portaldofranchising.com.br/franquia-the-best-acai/"
                target="blank"
                className="img"
              >
                <Selo src={seloabffooter} alt="Selo ABF 2024" />
              </a>
              <a
                href="https://revistapegn.globo.com/melhores-franquias/noticia/2024/06/conheca-as-franquias-5-estrelas-do-premio-as-melhores-franquias-do-brasil-2024.ghtml"
                target="_blank"
                rel="noreferrer"
                aria-hidden="true"
                className="img"
              >
                <Selo src={selopegn2024} alt="Melhores Franquias" />
              </a>
              <a
                href="https://hcl.org.br/empresas-amigas-do-hcl"
                target="_blank"
                rel="noreferrer"
                aria-hidden="true"
                className="img"
              >
                <Selo src={seloempresaamiga} alt="Empresa Amiga" />
              </a>
              <a
                href="https://pequenoprincipe.org.br/"
                target="_blank"
                rel="noreferrer"
                aria-hidden="true"
                className="img"
              >
                <Selo
                  src={selopequenoprincipe}
                  alt="Hospital Pequeno Príncipe"
                />
              </a>
            </div>
          </InfoContent>
          <Line />
          <InfoContent>
            <div>
              <Regulamentation>
                <a className="first-link" href="/regulamento">
                  Regulamento do Clube The Best
                </a>

                <a href="/privacidade">Privacidade de dados</a>
                <a href="/informacoes-nutricionais">Tabela nutricional</a>
                <a href="/seja-nosso-fornecedor">Seja nosso fornecedor</a>
              </Regulamentation>
              <span className="spanInfo">
                Imagens meramente ilustrativas. Consulte os restaurantes
                participantes. Todos os direitos reservados 2025.
              </span>
            </div>
            <img className="logofooter" src={logofooter} alt="Logo" />
          </InfoContent>
        </Content>
      </FooterContainer>
    </Container>
  );
};
