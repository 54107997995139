import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import arrow_up from "../../assets/svg/arrow_up.svg"

import { Flavor } from "../../model/CategoryFlavor";

import { getItemTag } from "./utils/getIconTag";

import {
  Container,
  ContainerInfo,
  FlavorNameTag,
  LeftSide,
  NutritionalTableContainer,
  OpenCloseInfo,
  RightSide,
  TagFlavor,
  TagsContainer,
  TopContainer,
} from "./styles";

interface SelectedFlavor {
  categoria: string;
  flavor: Flavor;
}

interface IProps {
  categoria: string;
  flavor: Flavor;
  id: string;
  setSelectedFlavor: Dispatch<SetStateAction<SelectedFlavor | null>>;
}

type TableValues = {
  nome: string;
  porcaoTotal: string;
  porcao: string;
  vd: number | null;
};

export const NutritionalTable: React.FC<IProps> = ({
  categoria,
  flavor,
  id,
  setSelectedFlavor,
}) => {
  const [tableValues, setTableValues] = useState<TableValues[]>([]);

  useEffect(() => {
    const _valueTable = Object.entries(
      flavor?.informacoes_nutricionais?.valores
    ).map(([nome, valores]) => ({
      nome,
      porcaoTotal: valores["100g"],
      porcao: valores.porcao,
      vd: valores.vd,
    }));
    setTableValues(_valueTable);
  }, [flavor]);

  return (
    <Container id={id}>
      <TopContainer>
        <h1>{categoria}</h1>
        <OpenCloseInfo onClick={() => setSelectedFlavor(null)}><img src={arrow_up}/> </OpenCloseInfo>
      </TopContainer>
      <TopContainer>
        <FlavorNameTag>{flavor.nome}</FlavorNameTag>
      </TopContainer>
      <ContainerInfo>
        <LeftSide>
          <span className="bold">
            Porção por embalagem:{" "}
            <span className="regular">
              {flavor?.informacoes_nutricionais?.porcoes_por_embalagem}
            </span>
          </span>
          <span className="bold">
            Porção:{" "}
            <span className="regular">
              {flavor?.informacoes_nutricionais?.porcao}
            </span>
          </span>
          <NutritionalTableContainer>
            <div className="table-header">
              <div className="name"></div>
              <div className="div-values">
                <div className="value">100g</div>
                <div className="value">60g</div>
                <div className="value">%VD*</div>
              </div>
            </div>
            {tableValues.map((_values) => (
              <div className="table-line" key={_values.nome}>
                <div className="name">{_values.nome}</div>
                <div className="div-values">
                  <div className="value">{_values.porcaoTotal}</div>
                  <div className="value">{_values.porcao}</div>
                  <div className="value">{_values.vd}</div>
                </div>
              </div>
            ))}
          </NutritionalTableContainer>
          <span>*Percentual de valores diários fornecidos pela porção</span>
        </LeftSide>
        <RightSide>
          <span className="bold">
            Ingredientes: <span className="regular">{flavor.ingredientes}</span>
          </span>
          <span className="uppercase-bold" style={{ marginTop: "1rem" }}>
            Alérgicos:{" "}
            <span className="uppercase-regular">{flavor.alergenicos}</span>
          </span>
          <TagsContainer style={{ marginTop: "1rem" }}>
            {flavor?.tags?.map((_tag) => (
              <TagFlavor>
                <p>{_tag}</p>
                <img src={getItemTag(_tag)} />
              </TagFlavor>
            ))}
          </TagsContainer>
        </RightSide>
      </ContainerInfo>
    </Container>
  );
};
