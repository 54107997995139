import styled, { css } from "styled-components";

import { RxMagnifyingGlass } from "react-icons/rx";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.125rem 16.25rem;
  margin-bottom: 3rem;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 1500px) {
    padding: 3rem 13rem;
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 10rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 3rem 8rem;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem 1rem;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
`;

export const TopContent = styled.section`
  padding-top: 3.125rem;
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

export const SectionSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  
  h1 {
    margin: 0;
    font-size: 3rem;
    font-family: "Visby-bold";
    @media screen and (max-width: 1550px) {
      font-size: 2.5rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 1.4rem;
    }
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 11rem;

  strong {
    color: var(--black);
    font-family: "Visby-demi-bold";
  }
`;

export const ContentInput = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 30rem;
  height: 3.5rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

export const InputFind = styled.input`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  border: 2px solid #f8aa00;
  border-radius: 2rem;
  font-size: 1.5rem;

  &::placeholder {
    color: var(--black);
    opacity: 30%;
    font-size: 1.5rem;
    font-family: "Visby-regular";

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 3rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 3rem;
    padding: 0 20px;
  }
`;

export const Icon = styled(RxMagnifyingGlass)`
  position: absolute;
  color: #f9a003;
  display: flex;
  right: 2rem;
`;

export const CategoryBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin: 1rem;

  .title {
    margin-bottom: 2rem;
    font-family: "Visby-bold";
    font-size: 3rem;
    color: var(--primary);
    text-transform: capitalize;

    @media only screen and (max-width: 750px) {
      font-size: 1.5rem;
    }
  }
`;

export const ContentFlavors = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media screen and (max-width: 1400px) {
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(max-content, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    width: 100%;
  }
`;

interface IFlavor {
  active: boolean;
}

export const FlavorBox = styled.div<IFlavor>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  border: 0.25px solid var(--gray-35);
  box-shadow: 10px 5px 1rem var(--gray-35-40);
  padding: 0.75rem 1rem !important;
  color: var(--gray-100);
  font-family: "Visby-demi-bold";
  font-size: 1rem;
  text-align: center;
  cursor: pointer;

  ${({ active }) => {
    if (active) {
      return css`
        background: var(--primary-40);
        color: var(--primary);
        border: 1px solid var(--primary);
      `;
    }
  }}
`;
