import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";

import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { Spinner } from "../../components/Spinner";
import { BannerHome } from "../../components/Banners";
import { NutritionalTable } from "../../components/NutritionalTable";

import { CategoryFlavor, Flavor } from "../../model/CategoryFlavor";

import { images_nutritional } from "./utils/images";
import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndLowerCase";

import {
  Container,
  TopContent,
  SectionSearch,
  ContentInput,
  InputFind,
  Icon,
  CategoryBox,
  ContentFlavors,
  FlavorBox,
} from "./styles";

interface ResponseType {
  categorias: CategoryFlavor[];
}

interface SelectedFlavor {
  categoria: string;
  flavor: Flavor;
}

export const NutritionalInfo = () => {
  const [loading, setLoading] = useState(false);
  const [categoryFlavorList, setCategoryFlavorList] = useState<ResponseType>();
  const [selectedFlavor, setSelectedFlavor] = useState<SelectedFlavor | null>(
    null
  );
  const [searchFlavor, setSearchFlavor] = useState<string>("");

  useEffect(() => {
    const fetchNutritionalInfo = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://amatech-prd.azure-api.net/api/janus/files-management/ti/clube-the-best-app/flavor-nutrition-data.json/beautify"
        );
        //@ts-ignore
        const data: ResponseType = await response.json();

        setCategoryFlavorList(data);
      } catch {
        Swal.fire({
          title: "Ocorreu um erro!",
          text: "Não foi possível recuperar os dados das tabelas nutricionais",
          timer: 4000,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchNutritionalInfo();
  }, []);

  const scrollTo = (id: string) => {
    const _id = document.getElementById(id);
    if (_id) {
      window.scrollTo({ top: _id.offsetTop, behavior: "smooth" });
    }
  };

  const searchNutritionalCategory = (searchValue: CategoryFlavor) => {
    if (searchFlavor) {
      return searchValue.sabores.some((_flavor) =>
        removeAccentsAndLowerCase(_flavor.nome)?.includes(
          removeAccentsAndLowerCase(searchFlavor)
        )
      );
    }
    return true;
  };
  const searchNutritionalFlavor = (searchValue: string) => {
    if (searchFlavor) {
      return removeAccentsAndLowerCase(searchValue)?.includes(
        removeAccentsAndLowerCase(searchFlavor)
      );
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Informações Nutricionais</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/informacoes-nutricionais`}
        />
        <meta property="og:image" content={images_nutritional.banner_nutritional} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={images_nutritional.banner_nutritional} />
      </Helmet>
      <Navbar />
      <BannerHome
        img={images_nutritional.banner_nutritional}
        alt="Banner Informações Nutricionais"
        text={false}
        imgMobile={images_nutritional.banner_nutritional}
      />
      <Container>
        <TopContent>
          <SectionSearch>
            <h1>Informação Nutricional</h1>
            <ContentInput>
              <InputFind
                placeholder={"Digite aqui"}
                onChange={(e) => setSearchFlavor(() => e.target.value)}
              />
              <Icon size={35} />
            </ContentInput>
          </SectionSearch>
        </TopContent>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {categoryFlavorList?.categorias
              ?.filter((_category) => searchNutritionalCategory(_category))
              .map((_category, index) => (
                <CategoryBox key={_category.categoria}>
                  {selectedFlavor &&
                  selectedFlavor.categoria === _category.categoria ? (
                    <NutritionalTable
                      categoria={selectedFlavor.categoria}
                      flavor={selectedFlavor.flavor}
                      id={`${index}`}
                      setSelectedFlavor={setSelectedFlavor}
                    />
                  ) : (
                    <span className="title">{_category.categoria}</span>
                  )}
                  <ContentFlavors>
                    {_category.sabores
                      .filter((_flavor) =>
                        searchNutritionalFlavor(_flavor.nome)
                      )
                      .map((_flavor) => (
                        <FlavorBox
                          key={_flavor.nome}
                          active={
                            _flavor?.nome === selectedFlavor?.flavor?.nome
                          }
                          onClick={() => {
                            setSelectedFlavor({
                              categoria: _category.categoria,
                              flavor: _flavor,
                            });
                            scrollTo(index.toString());
                          }}
                        >
                          {_flavor.nome}
                        </FlavorBox>
                      ))}
                  </ContentFlavors>
                </CategoryBox>
              ))}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};
