import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16.25rem;
  margin-bottom: 3rem;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 1550px) {
    padding: 0 13rem;
  }

  @media screen and (max-width: 1300px) {
    padding: 0 10rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 0 8rem;
  }
  @media screen and (max-width: 900px) {
    padding: 0 5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0 2rem 1rem;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
`;

export const TopContent = styled.section`
  display: flex;
  padding-top: 3.125rem;
  width: 100%;

  h1 {
    margin: 0;
    font-size: 3rem;
    font-family: "Visby-bold";
    @media screen and (max-width: 1550px) {
      font-size: 2.5rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 1.4rem;
    }
  }
  span {
    font-family: "Visby-bold";
    color: var(--primary);
  }

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;

  .totalRow {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    margin: 0.5rem 0;
  }

  .partialRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    margin: 0.5rem 0;

    @media only screen and (max-width: 750px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 1.9rem;
  font-family: "Visby-demi-bold";

  span {
    color: var(--primary);
    font-family: "Visby-demi-bold";
  }
  .transparent {
    color: transparent;
  }
  @media only screen and (max-width: 1750px) {
    font-size: 1.7rem;
  }
  @media only screen and (max-width: 1550px) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 750px) {
    font-size: 1rem;
  }
`;

export const Input = styled.input`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  margin-top: 0.5rem;
  padding: 0 2rem;
  border: 2px solid var(--primary);
  border-radius: 2rem;
  font-size: 1.9rem;
  font-family: "Visby-demi-bold";

  ::placeholder {
    font-family: "Visby-regular";
    opacity: 0.5;
    color: var(--gray-89);
  }
  @media only screen and (max-width: 1750px) {
    font-size: 1.7rem;
    height: 3.25rem;
  }
  @media only screen and (max-width: 1550px) {
    font-size: 1.5rem;
    height: 2.75rem;
  }
  @media only screen and (max-width: 750px) {
    font-size: 1rem;
    height: 2rem;
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 8.25rem;
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
  border: 2px solid var(--primary);
  border-radius: 2rem;
  font-size: 1.9rem;
  font-family: "Visby-demi-bold";
  ::placeholder {
    font-family: "Visby-regular";
    opacity: 0.5;
    color: var(--gray-89);
  }
  @media only screen and (max-width: 1750px) {
    font-size: 1.7rem;
  }
  @media only screen and (max-width: 1550px) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 750px) {
    font-size: 1rem;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
  .file {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    height: 1.1rem;
    span {
      font-size: 1rem;
      font-family: "Visby-bold";

      @media only screen and (max-width: 800px) {
        font-size: 0.7rem;
        max-width: 90%;
        overflow: hidden;
      }
    }
    .delete-file {
      cursor: pointer;
    }
  }
`;

export const InputUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 2.1rem;
  border-radius: 2rem;
  background: var(--primary);
  color: var(--black);
  font-size: 1rem;
  font-family: "Visby-demi-bold";
  position: relative;

  img {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
  }

  .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  width: 50%;

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const CheckBoxLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;

  span {
    font-size: 1.875rem;
    color: var(--gray-89);
    font-family: "Visby-demi-bold";

    @media only screen and (max-width: 1550px) {
      font-size: 1.5rem;
    }

    @media only screen and (max-width: 750px) {
      font-size: 1rem;
    }
  }

  .privacy-terms {
    font-size: 1rem;
    color: var(--gray-89);
    font-family: "Visby-demi-bold";
    margin: 0;

    @media only screen and (max-width: 750px) {
      font-size: 0.8rem;
    }
  }
  .privacy-terms-div {
    @media only screen and (max-width: 750px) {
      width: 3.5rem;
    }
  }
`;

interface IProps {
  active: boolean;
}

export const CheckBox = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid var(--primary);
  border-radius: 0.5rem;
  cursor: pointer;
  margin-right: 1rem;
  position: relative;

  ${({ active }) => {
    if (active) {
      return css`
        ::before {
          content: "";
          position: absolute;
          width: 50%;
          height: 50%;
          background-color: var(--primary);
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      `;
    }
  }}
`;

export const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
  height: 4.3rem;
  background: var(--primary);
  color: var(--black);
  font-size: 1.85rem;
  font-family: "Visby-bold";
  border-radius: 2rem;
  border: none;
  box-shadow: none;

  div {
    width: 3rem !important;
    height: 3rem !important;
  }

  svg {
    width: 3rem !important;
    height: 3rem !important;
  }

  :hover {
    opacity: 60%;
    border: none;
    box-shadow: none;
  }

  @media only screen and (max-width: 1550px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;
