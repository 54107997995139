import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.125rem 16.25rem;
  margin-bottom: 3rem;
  flex-direction: column;
  width: 100%;

  .modalContainer {
    height: 20%;
    width: 60%;
  }
  .modalHeader {
    padding: 1rem;
  }
  .modalBody {
    padding: 1rem;
  }

  @media screen and (max-width: 1500px) {
    padding: 3rem 13rem;
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 10rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 3rem 8rem;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem 1rem;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 11rem;

  strong {
    color: var(--black);
    font-family: "Visby-demi-bold";
  }

  a {
    text-decoration: none;
    color: var(--primary);

    :hover {
      text-decoration: underline;
    }
  }
`;

export const CountryBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin: 1rem;
  .title {
    margin-bottom: 2rem;
    font-family: "Visby-bold";
    font-size: 3rem;
    color: var(--primary);
    text-transform: capitalize;
  }
`;

export const ContentStores = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  width: 100%;

  @media screen and (max-width: 1400px) {
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(max-content, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    width: 100%;
  }
`;

export const ContentStoresState = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;

  @media screen and (max-width: 1550px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(max-content, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
  }
`;

export const StoreBox = styled.div`
  border: 2px solid var(--gray-50);
  border-radius: 1.25rem;
  height: 31rem;
  max-width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .rightinnerbox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }

  h4 {
    font-family: "Visby-regular";
    font-size: 1.3rem;
    color: var(--gray-100);
    height: 4rem;
    @media screen and (max-width: 1550px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 500px) {
      line-height: 1.2rem;
      font-size: 1rem;
    }
  }

  h6 {
    font-family: "Visby-regular";
    font-size: 1rem;
    color: var(--gray-95);
    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
    }
  }

  button {
    border: 1px solid var(--yellow-10);
    border-radius: 8px;
    height: 3.1rem;
    width: 11rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
    background-color: transparent;
    color: var(--yellow-10);
    font-family: "Visby-light";
    font-size: 1.2rem;
    cursor: pointer;

    @media screen and (max-width: 500px) {
      height: 1rem;
      padding: 1rem;
      font-size: 1rem;
      width: 9rem;
    }
  }

  @media screen and (max-width: 1400px) {
    margin-top: 1.5rem;
  }
`;

export const StoreBoxState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--gray-35);
  border-radius: 2rem;
  box-shadow: 10px 5px 1rem var(--gray-35-40);
  height: 4.75rem;
  width: 100%;
  padding: 0.5rem 1.5rem 0 !important;
  max-width: 100%;
  cursor: pointer;

  h4 {
    font-family: "Visby-regular";
    font-size: 1.5rem;
    color: var(--gray-100);
    text-transform: capitalize;
    &:hover {
      color: #f8aa00;
    }
    @media screen and (max-width: 500px) {
      line-height: 1.2rem;
      font-size: 1rem;
    }
  }

  p {
    font-family: "Visby-regular";
    font-size: 1rem;
    color: var(--gray-100);
    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 1400px) {
    margin-top: 1.5rem;
  }
`;

export const TopContent = styled.section`
  padding-top: 3.125rem;
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

export const SectionSearch = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  h1 {
    font-size: 3rem;
    font-family: "Visby-bold";
    @media screen and (max-width: 400px) {
      font-size: 1.4rem;
    }
  }

  .selectsStateCity {
    font-size: 2rem;
    text-transform: capitalize;

    .span-hover {
      :hover {
        color: var(--primary);
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 575px) {
      font-size: 1rem;
    }
  }

  select {
    width: 100%;
    height: 4.1rem;
    border-radius: 8px;
    border: 1px solid var(--gray-75);
    padding: 1.125rem 1.25rem;
    margin-right: 1.2rem;

    &::placeholder {
      color: var(--black);
      opacity: 30%;
      font-size: 1.5rem;
      font-family: "Visby-regular";
    }

    &::placeholder {
      font-family: "Visby-regular";
    }

    @media screen and (max-width: 500px) {
      height: 3rem;
      padding: 0.5rem;
    }
  }

  option {
    font-family: "Visby-regular";
    font-size: 1rem;
  }
`;

export const Line = styled.hr`
  width: 100%;
  color: var(--gray-75);
  height: 2px;
`;

export const ContentBox = styled.div`
  display: flex;
`;

export const BoxLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  @media screen and (max-width: 600px) {
    justify-content: space-around;
  }
`;

export const ButtonLocation = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29rem;
  padding: 1rem;
  border: 2px solid #f8aa00;
  border-radius: 2rem;
  background-color: transparent;
  font-family: "visby-bold";
  font-size: 1.625rem;
  color: #f8aa00;
  cursor: pointer;

  &:hover {
    color: var(--black);
    transition: 0.3s ease-out;
  }

  @media screen and (max-width: 1400px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 1.2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
  img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const StoreImage = styled.img`
  height: 13rem;
  object-fit: cover;
`;

export const DefaultImage = styled.img`
  height: 13rem;
  object-fit: cover;
`;
