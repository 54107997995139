import axios from "axios";

const API_URL = process.env.REACT_APP_API_EMAIL_URL;

const apiEmail = axios.create({
  baseURL: API_URL,
  responseType: "json",
});

apiEmail.interceptors.request.use((config) => {
  return config;
});

export default apiEmail;
