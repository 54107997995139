import styled, { css } from "styled-components";
import { ContainerHome } from "../../styles/globalStyles";
import { RxMagnifyingGlass } from "react-icons/rx";

import { Instagram } from "../../styles/icons";
import { ContainerImg } from "../../components/Banners/styles";

const BannerImg = require("../../assets/Images/Banner_Sympla/Banner-sympla.png");

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .modalContainer {
    display: flex;
    justify-content: space-between;
    width: 45%;
    height: auto;
    max-height: 45rem;
    background: var(--gray-10);

    @media screen and (max-width: 500px) {
      max-height: 35rem;
    }
    .modalHeader {
      padding: 0.5rem;
      .modalTitle {
        font-family: "Visby-bold";
        font-size: 1.5rem;
        @media screen and (max-width: 350px) {
          font-size: 1.6rem;
        }
      }
    }
    .modalBody {
      overflow-y: auto;
      background: var(--white);
      p {
        letter-spacing: 0;
        font-size: 1.2rem;
        @media screen and (max-width: 350px) {
          font-size: 0.7rem;
        }
      }
    }
  }
  .modalFooter {
    padding: 1rem 0 0 0;
    img {
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
    span {
      font-family: "Visby-bold";
      font-size: 1.1rem;
    }
  }
`;

export const BackgroundContainer = styled(ContainerImg)`
  background-image: url("${BannerImg}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 490px;
  position: absolute;
  z-index: 0;
  filter: blur(8px) brightness(0.9);

  @media screen and (max-width: 850px) {
    background-image: none;
    min-height: 0;
  }
  @media screen and (max-width: 575px) {
    background-image: url("${BannerImg}");
    min-height: 490px;
  }
  @media screen and (max-width: 490px) {
    min-height: 0;
  }
`;

export const ContainerBanner = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  object-fit: cover;
  max-height: 490px;
  border-radius: 0 0 2px 2px;
  z-index: 1;
  transition: ease-in-out 200ms;
`;

export const ContainerSearch = styled(ContainerHome)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
  ${ContainerHome}
`;

export const FindStore = styled.div`
  padding: 3.125rem 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FindStoreTitle = styled.h2`
  font-size: 3.21rem;
  text-align: center;
  font-family: "Visby-extrabold";
  span {
    font-family: "Visby-bold";
    display: contents;
    text-align: center;
    color: #f8aa00;
  }

  @media screen and (max-width: 1200px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

export const FindStoreSubtitle = styled.p`
  margin: 2rem 0 4.5rem;
  font-size: 1.5rem;
  color: var(--gray-100);
  font-family: "Visby-regular";
  text-align: center;
  letter-spacing: 0;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin: 1rem 0 2.2rem;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  width: 83%;
  height: 4.5rem;
  justify-content: space-between;
  position: relative;
  align-items: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

export const InputFind = styled.input`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 67%;
  height: 100%;
  padding: 0 40px;
  border: 2px solid #f8aa00;
  border-radius: 2rem;
  font-size: 1.5rem;

  &::placeholder {
    color: var(--black);
    opacity: 30%;
    font-size: 1.5rem;
    font-family: "Visby-regular";

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 3rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 3rem;
    padding: 0 20px;
  }
`;

export const ButtonLocation = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  border: 2px solid #f8aa00;
  border-radius: 2rem;
  background-color: transparent;
  font-weight: 700;
  font-size: 1.625rem;
  color: #f8aa00;
  cursor: pointer;

  &:hover {
    color: var(--black);
    transition: 0.3s ease-out;
  }

  @media screen and (max-width: 1400px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 3rem;
    margin-top: 1.2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

export const ContentAbout = styled(ContainerHome)`
  max-width: 100%;
  overflow: hidden;
  ${ContainerHome}
`;

export const About = styled.div`
  display: flex;
`;
export const Title = styled.h1`
  font-family: "Visby-bold";

  span {
    font-family: "Visby-bold";
    color: var(--primary);
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1.2rem;
    font-size: 2rem;
  }
  @media screen and (max-width: 500px) {
    text-align: center;
    font-size: 1.4rem;
  }
`;

export const ContentGeral = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentStickers = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 26rem;

  @media screen and (max-width: 1300px) {
    align-items: flex-start;
  }
  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const ContentStickerLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  width: 59%;

  p {
    color: var(--gray-100);
    width: 100%;
    font-size: 1.5rem
    letter-spacing: 0;

    @media screen and (max-width: 1600px) {
      font-size: 1.3rem;
    }
    @media screen and (max-width: 1400px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 0.9rem;
      text-align: left;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  `;

export const Stickers = styled.img`
  position: absolute;
  right: -2rem;
  width: 47%;
  object-fit: contain;
  z-index: 2;

  @media screen and (max-width: 1700px) {
    margin-top: 1rem;
  }

  @media screen and (max-width: 1500px) {
    margin-top: 2rem;
  }

  @media screen and (max-width: 1200px) {
    margin-top: 7%;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

interface IProps {
  img?: string;
}

export const AboutApp = styled.div<IProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 33.7rem;
  width: calc(100% + 27.5rem);
  margin: 3rem -13.5rem 0;
  background-color: var(--primary);
  padding: 5rem;

  ${({ img }) => {
    if (img) {
      return css`
        @media screen and (max-width: 1920px) {
          background: center url("${img}");
          background-repeat: no-repeat;
          background-size: cover;
        }
        @media screen and (max-width: 1024px) {
          background: none;
          background-color: var(--primary);
        }
      `;
    }
  }}

  @media screen and (max-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 500px) {
    height: 20rem;
  }
`;

export const ClubApp = styled.img``;

export const ContentAppAbout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  p {
    width: 100%;
    font-size: 3rem;
    font-weight: bold;
    line-height: 4rem;

    @media screen and (max-width: 1700px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    @media screen and (max-width: 1500px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 1rem;
      text-align: left;
      width: 50%;
    }
  }
  .span-bold {
    font-family: "Visby-extrabold";
    letter-spacing: 0;
  }
  img {
    margin-top: 0rem;
    @media screen and (max-width: 768px) {
      width: 15rem;
    }
  }
  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const ContentGalery = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  h3 {
    font-family: "Visby-bold";
    text-align: center;
    margin-bottom: 2.5rem;
    font-size: 3.12rem;
  }
  .primary {
    font-family: "Visby-bold";
    color: var(--primary);
  }

  @media only screen and (max-width: 500px) {
    h3 {
      margin: 1rem 0;
      text-align: center;
      font-size: 1.4rem;
    }
  }
`;

export const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
`;

export const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0.5rem;

  &:nth-child(1) {
    grid-column: span 1;
  }
  &:nth-child(2) {
    grid-column: span 2;
  }
  &:nth-child(3) {
    grid-column: span 1;
  }
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    grid-column: span 1;
  }
  &:nth-child(8) {
    grid-column: span 2;
  }
  &:nth-child(9) {
    grid-column: span 1;
  }
  &:nth-child(10) {
    grid-column: span 1;
  }
  &:nth-child(11) {
    grid-column: span 1;
  }
  &:nth-child(12) {
    grid-column: span 1;
  }
  &:nth-child(13) {
    grid-column: span 2;
  }

  @media only screen and (max-width: 500px) {
    padding: 0.2rem;
  }
`;

export const Icon = styled(RxMagnifyingGlass)`
  position: absolute;
  color: #f9a003;
  display: flex;
  left: 60%;

  @media screen and (max-width: 1024px) {
    left: 85%;
    top: 5%;
  }
`;

export const ContentSwiper = styled(ContainerHome)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ContainerHome}
`;

export const ContainerSwiper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  h3 {
    font-family: "Visby-bold";
    margin-bottom: 0;
    font-size: 3.1rem;
  }
  @media screen and (max-width: 500px) {
    h3 {
      text-align: center;
      font-size: 1.4rem;
    }
  }
`;

export const ContentTestimonial = styled.div`
  width: 100%;
  .body {
    display: inline-block;
    flex-direction: column;
  }
  .moreTestimonial {
    font-weight: bold;
    color: var(--primary);

    :hover {
      color: #f9a940;
      cursor: pointer;
    }
  }
`;

export const ContentNewPapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;
  h3 {
    font-family: "Visby-bold";
    margin-bottom: 0.5rem;
    font-size: 3.1rem;
  }

  @media screen and (max-width: 1024px) {
    h3 {
      text-align: center;
      margin-bottom: 0rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 4rem;
    h3 {
      text-align: center;
      font-size: 1.4rem;
    }
  }
`;

export const Materias = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  align-items: center;

  cursor: pointer;

  .btg,
  .istoEDinheiro {
    filter: grayscale(10);
  }
  img {
    opacity: 30%;
    width: 15rem;
    transition: 0.2s ease-in;
    :hover {
      opacity: 100%;
    }

    :nth-last-child() {
      margin-bottom: 3rem;
    }

    @media screen and (max-width: 1024px) {
      width: 10rem;
    }
  }

  @media screen and (max-width: 1724px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 0;
  }
`;

export const BannerSelo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  @media screen and (max-width: 1100px) {
    padding: 0 2rem;
  }
`;

export const SeloDesktop = styled.img`
  object-fit: contain;
  margin-top: 2.5rem;
  width: 100%;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const SeloMobile = styled.img`
  display: none;
  @media screen and (max-width: 1100px) {
    display: revert;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ContentLinks = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  div {
    display: flex;
    gap: 2rem;
    @media only screen and (max-width: 1024px) {
      gap: 1rem;
    }
  }

  a {
    height: 4.3rem;
    width: 13.4rem;

    @media only screen and (max-width: 650px) {
      width: 8.6rem;
      height: 3.1rem;
    }
    @media only screen and (max-width: 500px) {
      width: 6.6rem;
      height: 2.1rem;
    }
  }
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    width: auto;
  }
`;
