import { Helmet } from "react-helmet-async";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Container, ContentText, Subtitle, Title } from "./styles";

export const Regulamento = () => {
  return (
    <>
      <Helmet>
        <title>The Best Açaí - Regulamento</title>
        <link rel="canonical" href={`https://thebestacai.com.br/regulamento`} />
      </Helmet>
      <Navbar />
      <Container>
        <ContentText>
          <Title>Clube The Best - Regulamento</Title>
          <p>
            <strong> 1.1. </strong>{" "}
            <span className="span-underline">Objetivo.</span> Objetiva-se, com
            os termos aqui mencionados, regulamentar o Programa “Clube The Best”
            (ou “Programa de Recompensa”) instituído e promovido pelo{" "}
            <strong>GRUPO THE BEST FRANCHISING LTDA</strong>, pessoa jurídica de
            direito privado, inscrita no CNPJ sob o nº 28.967.949/0001-84, com
            sede na Rodovia Engenheiro Ângelo Lopes, s/n, sala A, Parque
            Industrial Nene Favoreto, CEP: 86.200-000, na cidade de Ibiporã,
            estado do Paraná, por meio do qual os clientes (“
            <span className="span-underline">Participantes</span>”) do “The Best
            Açaí” poderão conquistar o direito de recompensas, mediante o
            cumprimento das regras previstas neste Regulamento.
          </p>
          <br />
          <p>
            <strong>1.2. </strong>
            <span className="span-underline">
              Retirada de Recompensas.
            </span>{" "}
            O(A) Participante, que reside fora das cidades participantes do
            Programa, mas possui acesso a uma unidade do The Best Açaí, fica
            ciente que, ao alcançar os pontos necessários, precisará
            diligenciar-se, pessoalmente, em qualquer unidade franqueada para
            retirar a recompensa, sujeita à disponibilidade, não sendo possível
            receber o prêmio por frete.
          </p>
          <br />
          <p>
            <strong>2.1. </strong>
            <span className="span-underline">Período da Campanha.</span>{" "}
            Cronograma da Campanha de Recompensas: cada ponto conquistado terá
            validade de 3 (três) meses, contados a partir do dia em que for
            divulgada uma nova Campanha de Recompensa nos canais oficiais do The
            Best Açaí. Após esse período, o ponto perde a validade e o(a)
            Participante reconhece que não caberá qualquer tipo de restituição
            ou direito com relação a esses pontos vencidos. O resgate, contudo,
            está condicionado a disponibilidade do(s) respectivo(s) item(ns) no
            estoque. O The Best Açaí reserva-se o direito de alterar o prazo da
            campanha ou encerrá-la antecipadamente, a seu exclusivo critério,
            mediante comunicação prévia em suas redes sociais, no aplicativo The
            Best Club ou outro meio idôneo.
          </p>
          <br />
          <p>
            <strong>3.1.</strong>{" "}
            <span className="span-underline">Elegibilidade.</span> O Programa de
            Recompensas do The Best Açaí é aberto a qualquer pessoa física,
            consumidor final, residente e domiciliado(a) em território nacional,
            que, a partir da data de 05 de setembro de 2023, realizar compras
            presenciais em lojas físicas próprias e/ou franqueadas do The Best
            Açaí, no valor mínimo de R$ 5,00 (cinco reais), e que se inscreva
            dentro das regras previstas no Programa.
          </p>
          <br />
          <p>
            <strong>3.2.</strong>{" "}
            <span className="span-underline">Fraude.</span> As pessoas
            mencionadas acima, quando identificadas e/ou ainda aqueles que de
            alguma maneira manipularam, violaram ou fraudaram este regulamento
            para participar do Programa de Recompensas do The Best Açaí, não
            terão direito à recompensa.
          </p>
          <br />
          <Subtitle>CONDIÇÃO DE PARTICIPAÇÃO</Subtitle>
          <br />
          <p>
            <strong>4.1. </strong>
            <span className="span-underline">Pontuação.</span> A cada R$ 5,00
            (cinco reais) e seus múltiplos em compras realizadas nas lojas
            físicas do The Best Açaí, o(a) Participante terá direito a 01 (um)
            ponto de recompensa, mediante o escaneamento do QRCode estampado no
            cupom fiscal de venda gerado, no momento da compra, junto ao
            aplicativo “Clube The Best”, após a realização gratuita do cadastro
            do cliente no respectivo aplicativo e o prosseguimento nos moldes
            descritos logo abaixo.
          </p>
          <br />
          <p>
            <strong>4.2.</strong>{" "}
            <span className="span-underline">Exclusão de iFood.</span> Não é
            possível utilizar pontos de recompensa a partir de compras
            concretizadas em lojas do The Best Açaí, por meio do aplicativo do
            iFood. O presente Programa aplicar-se-á somente às compras
            operacionalizadas nas lojas físicas do The Best Açaí e suas
            franqueadas.
          </p>
          <br />
          <p>
            <strong>5.1.</strong>{" "}
            <span className="span-underline">Cadastro.</span> Para participar do
            Programa, os consumidores deverão instalar o aplicativo “Clube The
            Best” em seu dispositivo móvel e/ou ler o QRCode do cupom da venda
            gerada na compra e, inicialmente, informar o número de seu Cadastro
            Nacional de Pessoas Físicas do Ministério da Fazenda (CPF/MF).
          </p>
          <br />
          <p>
            <strong>5.2.</strong>{" "}
            <span className="span-underline">Avaliação.</span> Caso o(a)
            Participante já esteja cadastrado no aplicativo “Clube The Best”,
            o(a) Participante(a) sempre será redirecionado(a) a uma página de
            avaliação do atendimento para, após, receber o direito da pontuação.
            Caso o cliente não tenha cadastro, será necessário realizar este
            procedimento em que o(a) Participante deverá fornecer todos os dados
            pessoais solicitados, sendo logo após direcionado para a avaliação
            do atendimento.
          </p>
          <br />
          <p>
            <strong>5.3.</strong>{" "}
            <span className="span-underline">Validação.</span> Após a realização
            completa da avaliação no aplicativo “Clube The Best”, basta o(a)
            participante escanear o QRCode do cupom fiscal da venda gerada na
            compra no referido aplicativo e inseri-lo corretamente no
            aplicativo, para contabilização dos pontos.
          </p>
          <br />
          <p>
            <strong>6.1.</strong>{" "}
            <span className="span-underline">Uso pessoal.</span> O cadastro é
            pessoal, individual e intransferível, e será utilizado como forma de
            identificação do(a) Participante para fins de registro de cupons
            fiscais emitidos pelas lojas físicas participantes do The Best Açaí,
            nos termos do presente regulamento. Caso o(a) Participante
            disponibilize as suas informações de uso pessoal a terceiros,
            eventual acesso indevido a tais informações serão de
            responsabilidade do(a) Participante, eximindo o The Best Açaí de
            qualquer responsabilidade correspondente.
          </p>
          <br />
          <p>
            <strong>7.1.</strong>
            <span className="span-underline">Responsabilidade.</span> O
            consumidor garante que as informações prestadas no cadastro possuem
            total veracidade, assumindo inteira responsabilidade pelo teor. O(a)
            Participante será, automaticamente, desclassificado e excluído do
            Programa, sem necessidade de prévia comunicação, caso seja (i)
            informado dado pessoal incorreto, falso ou incompleto e/ou (ii)
            praticado qualquer tipo de ato considerado fraudulento, ilegal,
            ilícito, ou que atente contra os objetivos deste Programa e do seu
            regulamento, sem prejuízo das demais penalidades cabíveis.
          </p>
          <br />
          <p>
            <strong>8.1.</strong>{" "}
            <span className="span-underline">Cadastro.</span> É de exclusiva
            responsabilidade do(a) Participante a realização correta do
            cadastramento no aplicativo “Clube The Best”, bem como da inclusão
            no referido aplicativo do QRCode presente no cupom da venda gerada
            na compra, de acordo com as instruções descritas neste regulamento,
            sob pena de não participação do Programa.
          </p>
          <br />
          <p>
            <strong>9.1.</strong>{" "}
            <span className="span-underline">Validade dos Cupons.</span> Será
            inutilizada qualquer prova de compra e/ou pontuação, que apresente
            indícios de reprodução ou adulteração com o objetivo de burlar as
            disposições constantes neste regulamento ou, ainda, que apresente
            defeitos ou vícios que impeçam a verificação de sua autenticidade ou
            do direito ao cupom.
          </p>
          <br />
          <p>
            <strong>9.1.1.</strong>{" "}
            <span className="span-underline">Suspensão.</span> O The Best Açaí
            reserva-se o direito de suspender ou cancelar imediatamente,
            independente de notificação, o acesso do(a) Participante ao
            aplicativo “Clube The Best” e recusar que use, presente ou
            futuramente, as respectivas funcionalidades, caso exista (i) algum
            indício, seja a título que for, de infração aos Termos, (ii)
            utilização de má-fé, de forma fraudulenta, ou ardil, ou, ainda,
            (iii) forneça qualquer informação falsa, incorreta, incompleta. A
            exclusão considera-se penalidade definitiva que implica na perda de
            todos os seus direitos relacionados ao Clube The Best, inclusive os
            Pontos, de forma irreversível, independentemente de notificação.
          </p>
          <br />
          <p>
            <strong>9.1.2.</strong>{" "}
            <span className="span-underline">Revisão.</span> Caso o(a)
            Participante tenha o acesso ou a pontuação suspensa ou cancelada,
            conforme previsto na Cláusula 9.1.1, poderá apresentar pedido de
            revisão contra a decisão, no prazo de 15 (quinze) dias antes do
            encerramento do Período da Campanha. Para tanto, o(a) Participante
            deverá formalizar seu recurso por e-mail, encaminhado para
            suporte@thebestacai.com.br, anexando obrigatoriamente: (i)
            evidências concretas que comprovem a veracidade dos pontos
            acumulados; (ii) cópias dos cupons fiscais originais correspondentes
            às compras realizadas; (iii) comprovantes de pagamento que
            demonstrem a legitimidade das transações; (iv) justificativa
            detalhada sobre a contestação da suspensão ou cancelamento. Durante
            o período de análise, os pontos permanecerão bloqueados. A ausência
            de envio de todas as informações requeridas dentro do prazo
            estipulado implicará na manutenção da suspensão ou cancelamento, sem
            direito a novos recursos.
          </p>
          <br />
          <p>
            <strong>10.1.</strong>{" "}
            <span className="span-underline">Prazo de Participação.</span> Não
            serão aceitos os cupons de compra cujas transações tenham sido
            efetuadas fora do período de participação do Programa de Recompensas
            do The Best Açaí.
          </p>
          <br />
          <p>
            <strong>11.1.</strong>{" "}
            <span className="span-underline">Conduta.</span> Ficam os
            participantes, cientes, desde já, que não poderão utilizar de meios
            escusos para obter os cupons fiscais com QRCode para participar do
            Programa de Recompensas do The Best Açaí e/ou de mecanismos que
            criem condições de cadastramento irregular, desleais, fraudulentos
            ou que atentem contra os objetivos e condições de participação
            previstas neste regulamento, situações essas que quando
            identificadas, serão consideradas, a qualquer momento, como infração
            aos termos do presente regulamento, ensejando o impedimento da
            participação no Programa, sem prejuízo, ainda, das medidas cabíveis
            a ser(em) promovida(s) em face do infrator.
          </p>
          <br />
          <p>
            <strong>12.1.</strong>{" "}
            <span className="span-underline">Intransferível.</span> Os pontos
            acumulados no cadastro de um participante não poderão ser repassados
            para outro consumidor, seja parente, cônjuge ou amigo.
          </p>
          <br />
          <p>
            <strong>13.1.</strong>{" "}
            <span className="span-underline">Expiração dos Pontos.</span> Ao
            término do Período da Campanha, os pontos de todos os participantes
            serão zerados, não havendo mais possibilidade de resgate de nenhum
            prêmio.
          </p>
          <br />
          <p>
            <strong>14.1. </strong>{" "}
            <span className="span-underline">Limite de Pontos.</span> Há um
            limite de 200 (duzentos) pontos por CPF para o presente Programa. O
            direito ao recebimento do ponto está condicionado à realização de
            cada avaliação. Caso o(a) participante atinja o limite máximo do
            Programa, não será possível acumular mais pontos.
          </p>
          <br />
          <p>
            <strong>14.2.</strong>{" "}
            <span className="span-underline">
              Alteração da Quantidade Máxima.
            </span>{" "}
            A quantidade máxima de pontos acumuláveis por CPF e a quantidade
            máxima de cada item de recompensa podem ser alteradas sem aviso
            prévio.
          </p>
          <br />
          <Subtitle>RECOMPENSAS</Subtitle>
          <br />
          <p>
            <strong>15.1.</strong>{" "}
            <span className="span-underline">Resgate.</span> As recompensas
            deste Programa poderão ser resgatadas, de acordo com (i) os pontos
            acumulados pelo(a) Participante, (ii) exigidos em cada item
            disponível no aplicativo e (iii) sujeitos a disponibilidade no
            estoque da loja participante.
          </p>
          <br />
          <p>
            <strong>15.2.</strong>{" "}
            <span className="span-underline">Validade da Recompensa.</span> Caso
            o(a) Participante troque seus pontos por uma recompensa e não a
            retire até a data estipulada na descrição da recompensa, não será
            mais possível fazer o resgate da recompensa e os pontos serão
            perdidos, sem qualquer direito a indenização ou reembolso.
          </p>
          <br />
          <p>
            <strong>15.3.</strong>{" "}
            <span className="span-underline">Irreversibilidade.</span> Caso o(a)
            Participante escolha uma recompensa para trocar pelos seus pontos e
            aceite os termos expostos no aplicativo “Clube The Best”, não será
            mais possível desfazer a ação. O(A) Participante terá o direito
            somente de retirar a recompensa já escolhida, até o prazo estipulado
            na descrição da recompensa, no entanto não será possível cancelar a
            requisição já concluída.
          </p>
          <br />
          <p>
            <strong>15.4.</strong>{" "}
            <span className="span-underline">Disponibilidade.</span> As
            recompensas previstas neste Regulamento estão sujeitas à
            disponibilidade de estoque das lojas participantes, além da
            finalização de cada etapa de avaliação.
          </p>
          <br />
          <Subtitle>ENTREGA DA RECOMPENSA</Subtitle>
          <br />
          <p>
            <strong>16.1.</strong>{" "}
            <span className="span-underline">Local </span>de Retirada. A
            recompensa pode ser retirada em qualquer loja do The Best Açaí da
            escolha do participante, sujeita à disponibilidade de estoque.
          </p>
          <br />
          <p>
            <strong>16.2.</strong>{" "}
            <span className="span-underline">Comprovação.</span> Como condição
            ao recebimento da recompensa, o(a) Participante deverá apresentar o
            seu cadastro no aplicativo “Clube The Best” e demonstrar que houve a
            troca de seus pontos pela recompensa escolhida.
          </p>
          <br />
          <p>
            <strong>16.3.</strong>{" "}
            <span className="span-underline">Retirada.</span> A recompensa será
            retirada pessoalmente pelo(a) próprio(a) Participante, que deverá
            apresentar a documentação necessária à sua inequívoca identificação.
          </p>
          <br />
          <p>
            <strong>16.4.</strong>{" "}
            <span className="span-underline">Intransferível.</span> A recompensa
            escolhida é pessoal e intransferível, não sendo admitida sua
            negociação, cessão, comercialização ou substituição por outra
            espécie de bens ou serviços, nem sua conversão em dinheiro, em
            nenhuma hipótese.
          </p>
          <br />
          <p>
            <strong>16.5.</strong>{" "}
            <span className="span-underline">Responsabilidade.</span> A
            responsabilidade do The Best Açaí cessará com a entrega da
            recompensa ao(a) Participante. Qualquer reclamação relativa às
            especificações, qualidade e garantia das recompensas concedidas
            deverão ser realizadas perante a empresa fabricante do produto.
          </p>
          <br />
          <Subtitle>PRESCRIÇÃO</Subtitle>
          <br />
          <p>
            <strong>17.1.</strong>{" "}
            <span className="span-underline">Prazo.</span> A recompensa deverá
            ser reclamada dentro do prazo de duração do Programa. Após, esse
            prazo caducará o direito do(a) Participante.
          </p>
          <br />
          <Subtitle>DISPOSIÇÕES GERAIS</Subtitle>
          <br />
          <p>
            <strong>18.1. </strong>{" "}
            <span className="span-underline">Divulgação.</span> Exibição das
            recompensas: as imagens das recompensas serão expostas nas
            dependências das lojas do The Best Açaí, bem como por meios de
            diversos canais de divulgação do Programa, sendo todas meramente
            ilustrativas.
          </p>
          <br />
          <p>
            <strong>19.1.</strong>{" "}
            <span className="span-underline">Uso de  imagem.</span> O
            cadastramento no Programa implica na cessão da imagem do(a)
            Participante, para fins de divulgação daquela, sem quaisquer ônus ao
            The Best Açaí, ficando totalmente livre para usar as referidas
            imagens em quaisquer peças publicitárias, desde já autorizada a sua
            veiculação em jornal, rádio, TV, outdoor, internet, redes sociais ou
            qualquer outro veículo de comunicação ou mídia, pelo período de um
            ano contado da data da apuração.
          </p>
          <br />
          <p>
            <strong>20.1.</strong>{" "}
            <span className="span-underline">Consentimento.</span> A
            participação no Programa de Recompensas do The Best Açaí caracteriza
            a aceitação e o reconhecimento integral dos termos e condições do
            presente regulamento. Em relação à operação de tratamento de dados
            pessoais, e em respeito às regras da Lei Geral de Proteção de Dados,
            o The Best Açaí recomenda a leitura na íntegra do Aviso de
            Privacidade.
          </p>
          <br />
          <p>
            <strong>21.1.</strong>{" "}
            <span className="span-underline">Revisão.</span> O regulamento do
            Programa de Recompensas do The Best Açaí poderá ser revisto e
            alterado sem necessidade de qualquer aviso prévio. Para tanto,
            orientamos que o regulamento seja periodicamente consultado, seja no
            site institucional do The Best Açaí ou no aplicativo The Best Club.
          </p>
          <br />
          <p>
            <strong>22.2.</strong>{" "}
            <span className="span-underline">Prevalência.</span> Todos os
            comunicados, avisos, campanhas, regras e diretrizes contidas no
            aplicativo The Best Club deverão ser lidos conjuntamente com as
            disposições deste regulamento. Em caso de divergência entre as
            disposições deste regulamento e das contidas no aplicativo The Best
            Club, prevalecerão as últimas.
          </p>
          <br />
          <p>
            <span className="span-underline">Foro.</span> Fica, desde já, eleito
            o Foro da Comarca de Londrina, estado do Paraná, para a solução de
            quaisquer questões referentes ao regulamento da promoção autorizada.
          </p>
          <br />
          <p>
            Estes Termos de Uso foram atualizados e serão aplicados ao uso dos
            serviços do Clube The Best a partir de 10/03/2025.{" "}
          </p>
          <br />
          <div className="franchising">
            <h2>GRUPO THE BEST FRANCHISING LTDA</h2>
            <p>28.967.949/0001-84</p>
          </div>
          <div className="update">
            <p>Última atualização: 10 de março de 2025.</p>
          </div>
        </ContentText>
      </Container>
      <Footer />
    </>
  );
};
