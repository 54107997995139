import styled from "styled-components";

export const TestimonialBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 33.4rem;
  height: 20rem;
  padding: 2.75rem;
  border: 1px solid var(--gray-50);
  border-radius: 20px;
  max-width: 100%;
  margin: 0 0.2rem;

  h4 {
    font-family: "Visby-bold";
    font-size: 1.3rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 1100px) {
      margin-bottom: 0.2rem;
    }
  }

  p {
    font-size: "Visby-light";
    font-size: 1.2rem;
    color: var(--gray-150);
    letter-spacing: normal;

    @media screen and (max-width: 1100px) {
      font-size: 1rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 0.9rem;
    }
  }

  .body {
    overflow: auto;
  }

  aside {
    display: flex;
    align-items: center;
    align-items: center;
    margin: 1rem;

    @media screen and (max-width: 400px) {
      margin-top: 0rem;
    }

    img {
      border-radius: 50%;
      width: 3.3rem;
      height: 3.3rem;
      margin-right: 1rem;
      object-fit: cover;
    }

    .content_bottom {
      display: flex;
      flex-direction: column;
    }

    span {
      font-family: "Visby-bold";
      font-size: 1.1rem;

      @media screen and (max-width: 1100px) {
        font-size: 1rem;
      }
    }
    p {
      font-size: "Visby-light";
      font-size: 1rem;
      color: var(--gray-150);
      margin-top: 0.1rem;

      @media screen and (max-width: 1100px) {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 1700px) {
    width: 30rem;
  }
  @media screen and (max-width: 1600px) {
    width: 28rem;
    padding: 1.75rem;
  }
  @media screen and (max-width: 1400px) {
    width: 25rem;
    padding: 1rem;
    justify-content: center;
  }
  @media screen and (max-width: 1100px) {
    width: 20rem;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
