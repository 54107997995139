import { useEffect } from "react";
import { Route, BrowserRouter, Routes, useLocation } from "react-router-dom";
import { Home } from "../pages/Home";
import { Lojas } from "../pages/NossasLojas";
import { Sobre } from "../pages/Sobre";
import { TheBestApp } from "../pages/TheBestApp";
import { TrabalheConosco } from "../pages/TrabalheConosco";
import { StoreMap } from "../pages/StoreMap";
import { Privacidade } from "../pages/Privacidade";
import { Blog } from "../pages/Blog";
import { SinglePost } from "../pages/SinglePost";
import { NutritionalInfo } from "../pages/NutritionalInfo";
import { Auth } from "../pages/Auth";
import { Regulamento } from "../pages/RegulamentoTheBestApp";
import { SupplierForm } from "../pages/SupplierForm";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/lojas" element={<Lojas />} />
        <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
        <Route path="/thebest-club" element={<TheBestApp />} />
        <Route path="/mapa-the-best" element={<StoreMap />} />
        <Route path="/informacoes-nutricionais" element={<NutritionalInfo />} />
        <Route path="/seja-nosso-fornecedor" element={<SupplierForm />} />
        <Route path="/privacidade" element={<Privacidade />} />
        <Route path="/regulamento" element={<Regulamento />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<SinglePost />} />
        <Route path="/admin" element={<Auth />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
