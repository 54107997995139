import { Helmet } from "react-helmet-async";

import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { TimeLine } from "../../components/TimeLine";

import collaborators from "../../assets/Icons/collaborators.svg";
import store from "../../assets/Icons/store.svg";
import graphic from "../../assets/Icons/graphic.svg";
import icecream from "../../assets/svg/icecream.svg";
import minimap from "../../assets/svg/minimap.svg";
import organograma from "../../assets/Images/organograma.png";
import selopegn2023 from "../../assets/Images/selopegn2023.png";
import selopegn2024 from "../../assets/Images/selopegn2024.png";
import seloexame from "../../assets/Images/seloexame.png";
import seloforbes from "../../assets/Images/seloforbes.png";
import seloabf from "../../assets/Images/seloabf.png";
import selotopmkt from "../../assets/Images/selotopmkt.png";

import { images_about } from "./utils/images";
import { principlesOdd } from "./utils/principles";

import {
  AboutContainer,
  Container,
  Icons,
  IconsCards,
  IconsContainer,
  Title,
  IconTitle,
  IconText,
  TrajectoryContainer,
  TrajectoryImg,
  TrajectoryTitle,
  TrajectoryText,
  TrajectoryTextContainer,
  TimeLineContainer,
  VideoContainer,
  VideoRep,
  PrinciplesContainer,
  PrinciplesCardsContainer,
  PrinciplesCards,
  PrinciplesText,
  PrinciplesIcon,
  PrinciplesIconContainer,
  PrinciplesTitle,
  PrinciplesTextContainer,
  PageContainer,
  ChartContainer,
  AchievementsContainer,
} from "./styles";

export const Sobre = () => {
  const menuIcons = [
    {
      id: 1,
      icon: collaborators,
      title: (
        <>
          <b>
            + DE <strong>2000</strong>
          </b>
          <b>COLABORADORES</b>
        </>
      ),
      text: " ",
      alt: "Colaboradores",
    },
    {
      id: 2,
      icon: store,
      title: (
        <>
          <b>
            + DE <strong>590</strong>
          </b>
          <b> LOJAS ABERTAS</b>
        </>
      ),
      text: " ",
      alt: "Lojas Abertas",
    },
    {
      id: 3,
      icon: graphic,
      title: (
        <>
          <b>
            + DE <strong>800</strong>
          </b>
          <b>
            <strong>MILHÕES</strong> DE FATURAMENTO
          </b>
        </>
      ),
      text: " ",
      alt: "Faturamento",
    },
    {
      id: 4,
      icon: icecream,
      title: (
        <>
          <b>
            + DE
            <strong> 40 TONELADAS</strong> DE
          </b>
          <b>AÇAÍ E SORVETE</b>
          <b>PRODUZIDOS POR MÊS</b>
          <b />
        </>
      ),
      text: "  ",
      alt: "Estados",
    },
    {
      id: 5,
      icon: minimap,
      title: (
        <>
          <b>PRESENTE EM </b>
          <b>VÁRIAS REGIÕES</b>
          <b />
        </>
      ),
      text: "  ",
      alt: "Regiões",
    },
  ];

  return (
    <PageContainer>
      <Helmet>
        <title>The Best Açaí - Nossa história</title>
        <link rel="canonical" href={`https://thebestacai.com.br/sobre`} />
        <meta property="og:image" content={images_about.banner_about} />
        <meta name="twitter:image" content={images_about.banner_about} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Navbar />
      <BannerHome
        img={images_about.banner_about}
        alt="Banner da página Sobre nós. Na foto, imagens de uma franquia"
        text={false}
        imgMobile={images_about.banner_about}
      />
      <Container>
        <AboutContainer>
          <IconsContainer>
            {menuIcons.map((cards) => (
              <IconsCards key={cards.id}>
                <Icons src={cards.icon} alt={cards.alt} />
                <IconTitle>{cards.title}</IconTitle>
                <IconText>{cards.text}</IconText>
              </IconsCards>
            ))}
          </IconsContainer>
        </AboutContainer>

        <TrajectoryContainer>
          <TrajectoryTextContainer>
            <TrajectoryTitle>
              Quem <strong>Somos?</strong>
            </TrajectoryTitle>
            <TrajectoryText>
              <p>
                A <b>The Best Açaí</b> nasceu em 2017 a partir da força de
                vontade e olhar visionário de três jovens empreendedores{" "}
                <b>Mateus Bragatto, Mateus Queiroz</b> e <b>Sérgio Kendy</b> que
                tem como propósito democratizar o consumo do açaí. Com a
                premissa de oferecer qualidade e diversidade de opções em torno
                da fruta em um modelo self-service focado na experiência do
                consumidor, a rede tem mais de 600 lojas – sendo 15 próprias –
                na Bahia, Distrito Federal, Espírito Santo, Goiáis, Mato Grosso,
                Mato Grosso do Sul, Minhas Gerais, Paraná, Rio de Janeiro, Rio
                Grande do Sul, Santa Catarina, São Paulo e Tocantins e no
                Paraguai em Alto Paraná, Conindeyú, Itapúa, Distrito Capital e
                Distrito Central.
              </p>
              <br></br>
              <p>
                A <b>Amadelli</b>, fábrica e distribuidora dos produtos
                <b> The Best Açaí</b>, está localizada em Ibiporã, no Paraná, e
                produz 22 toneladas de açaí e sorvete diariamente. A estrutura
                agrega 12.000 m² de área construída e tecnologia de ponta que
                garante a qualidade e o sabor incomparável dos produtos
                encontrados em todas as lojas <b>The Best Açaí</b>. Fazem parte
                do{" "}
                <b>Grupo The Best</b> as marcas <b>The Best Açaí</b>, a{" "}
                <b>Amadelli</b> e a <b>AmaFruta</b>. Acesse o maior Instagram de
                açaí do mundo:
                <a
                  href="https://www.instagram.com/thebestacaiofficial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  @thebestacaiofficial
                </a>
              </p>
            </TrajectoryText>
          </TrajectoryTextContainer>
          <TrajectoryImg
            src={images_about.map_about}
            alt="Foto do mapa do Brasil, com pontos onde tem unidades do The Best Açaí"
          />
        </TrajectoryContainer>

        <ChartContainer>
          <img src={organograma} alt="Organograma" />
          <div>
            <p>
              “Juntos, vamos transformar cada desafio em uma oportunidade e cada
              ideia em realidade!”
            </p>
            <br />
            <p>- Mensagem da Diretoria.</p>
          </div>
        </ChartContainer>

        <TimeLineContainer>
          <Title>
            <strong>Linha do tempo</strong>
          </Title>
          <TimeLine />
        </TimeLineContainer>
        <AchievementsContainer>
          <Title>
            <strong>Nossas Conquistas</strong>
          </Title>
          <div>
            <img src={selopegn2023} alt="Selo PEGN 2023 " />
            <img src={selopegn2024} alt="Selo PEGN 2024" />
            <img src={seloexame} alt="Selo Exame" />
            <img src={seloforbes} alt="Selo Forbes" />
            <img src={seloabf} alt="Selo ABF" />
            <img src={selotopmkt} alt="Selo top MKT" />
          </div>
        </AchievementsContainer>

        <VideoContainer>
          <Title>Conheça mais sobre nós!</Title>

          <VideoRep src="https://www.youtube.com/embed/QhHpdbbk63g">
            Seu navegador não suporta HTML5.
          </VideoRep>
        </VideoContainer>
        <PrinciplesContainer>
          <Title className="titlePrinciples">Princípios da The Best</Title>
          <PrinciplesCardsContainer>
            {principlesOdd.map((cards) => (
              <PrinciplesCards key={cards.id}>
                <PrinciplesIconContainer>
                  <PrinciplesIcon src={cards.icon} alt={cards.alt} />
                </PrinciplesIconContainer>
                <PrinciplesTextContainer>
                  <PrinciplesTitle>{cards.title}</PrinciplesTitle>
                  <PrinciplesText>{cards.text}</PrinciplesText>
                </PrinciplesTextContainer>
              </PrinciplesCards>
            ))}
          </PrinciplesCardsContainer>
        </PrinciplesContainer>
      </Container>

      <Footer active={true} />
    </PageContainer>
  );
};
