import { useState } from "react";
import { Helmet } from "react-helmet-async";

import discount from "../../assets/Icons/discount.svg";
import safeApp from "../../assets/Icons/safeApp.svg";
import points from "../../assets/Icons/points.svg";
import pointCoins from "../../assets/Icons/pointCoins.svg";
import Arrow from "../../assets/Images/arrow-min.png";
import questionClose from "../../assets/Icons/questionClose.svg";
import questionOpen from "../../assets/Icons/questionOpen.svg";
import Items from "../../assets/Icons/bolsas.svg";

import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { ModalApp } from "../../components/ModalApp";

import { useModal } from "../../hooks/useModal";

import faqApp from "../../model/faqApp.json";

import { removeAccents } from "../../helpers/removeAccents";

import { images_club } from "./utils/images";

import {
  ButtonClub,
  CardsContainer,
  Container,
  ContentItems,
  ContentPrize,
  IconArrowContainer,
  Icons,
  IconsCards,
  IconsContainer,
  IconText,
  IconTitle,
  ImgContainer,
  Imgs,
  ImgsArrow,
  ImgText,
  ImgTitle,
  InputFind,
  QuestionIcon,
  QuestionIconContent,
  QuestionsCards,
  QuestionsContainer,
  QuestionsText,
  QuestionsTitle,
  QuestionsTitleContainer,
  SpanContent,
  Title,
  TitleContent,
  Uppercase,
  Text,
  ContainerTextBanner,
  ContentInput,
  ButtonClubDownload,
  IconSearch,
} from "./styles";

export const TheBestApp = () => {
  const imgAppTheBest1 = require("../../assets/Images/imgAppTheBest1.webp");
  const imgAppTheBest2 = require("../../assets/Images/imgAppTheBest2.webp");
  const imgAppTheBest3 = require("../../assets/Images/imgAppTheBest3.webp");

  const [itemModalOpen, setItemModalOpen] = useModal();
  const [search, setSearch] = useState("");

  const menuIcons = [
    {
      id: 1,
      icon: safeApp,
      title: <Uppercase>pontos que valem prêmios</Uppercase>,
      text: (
        <Text>
          Acumule pontos e troque por mais açaís, sorvetes e prêmios exclusivos.
        </Text>
      ),
      alt: "Melhor Preço",
    },
    {
      id: 2,
      icon: pointCoins,
      title: <Uppercase>news</Uppercase>,
      text: (
        <Text>Seja o primeiro a saber de todas as novidades e  promoções!</Text>
      ),
      alt: "App Seguro",
    },
    {
      id: 3,
      icon: discount,
      title: <Uppercase>Vouchers de desconto</Uppercase>,
      text: (
        <Text>
          Os Besters tem vouchers exclusivos para apresentar no caixa da loja e
          economizar! Ative o código para receber o desconto.
        </Text>
      ),
      alt: "Pontos",
    },
    {
      id: 4,
      icon: points,
      title: <Uppercase>Acumule + pontos!</Uppercase>,
      text: <Text>Campanhas ESPECIAIS com ponto em dobro!​!</Text>,
      alt: "Pontos",
    },
  ];
  const appImages = [
    {
      id: 1,
      icon: imgAppTheBest1,
      title: <TitleContent>Baixe o Clube The Best</TitleContent>,
      text: (
        <SpanContent>
          É FÁCIL! Baixe o APP Clube The Best e faça o seu cadastro!
        </SpanContent>
      ),
      alt: "Clube The Best",
    },
    {
      id: 2,
      icon: imgAppTheBest2,
      title: <TitleContent>Resgate seu cupom</TitleContent>,
      text: (
        <SpanContent>
          Cada R$5,00 você acumula 1 ponto em qualquer loja The Best Açaí, só
          informar o seu CPF, a pontuação é na hora!
        </SpanContent>
      ),
      alt: "Copos",
    },
    {
      id: 3,
      icon: imgAppTheBest3,
      title: <TitleContent>Pegue seu item</TitleContent>,
      text: (
        <SpanContent>
          Tudo no APP do Clube! Você confere seus pontos, escolhe a recompensa e
          resgata em qualquer loja The Best Açaí.
        </SpanContent>
      ),
      alt: "Copos do cupom",
    },
  ];

  const questions = faqApp;

  const [questionActive, setQuestionActive] = useState<boolean[]>(
    new Array(questions.length).fill(false)
  );

  const handleOnChange = (position: number) => {
    const updatedState = questionActive.map((item, index) =>
      index === position ? !item : item
    );

    setQuestionActive(updatedState);
  };

  const findQuestion = (value: string) => {
    const formattedValue = removeAccents(value);

    return formattedValue?.length
      ? questions.filter((question) =>
          removeAccents(question.text).includes(formattedValue)
        )
      : questions;
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Clube The Best</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/thebest-club`}
        />
        <meta property="og:image" content={images_club.banner_club} />
        <meta name="twitter:image" content={images_club.banner_club} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Navbar />
      <BannerHome
        img={images_club.banner_club}
        alt="Banner da página App The Best"
        text={false}
        page={"theBestApp"}
        imgMobile={images_club.banner_club_mobile}
        onClickEvent={() => setItemModalOpen(true)}
      >
        <ContainerTextBanner>
          <span>
            Troque PONTOS por mais açaís, sorvetes E recompensas exclusivas!
          </span>
          <ButtonClubDownload
            href="https://play.google.com/store/apps/details?id=com.amatech.thebestclubapp"
            target="_blank"
            rel="noreferrer"
            aria-hidden="true"
          >
            Clique aqui e baixe o Clube The Best!
          </ButtonClubDownload>
        </ContainerTextBanner>
      </BannerHome>
      <Container>
        <CardsContainer background="#F6A000">
          <Title className="clubTheBestTitle">
            Vantagens do <span>Clube The Best</span>
          </Title>

          <IconsContainer>
            {menuIcons.map((cards) => (
              <IconsCards key={cards.id}>
                <Icons src={cards.icon} alt={cards.alt} />
                <IconTitle>{cards.title}</IconTitle>
                <IconText>{cards.text}</IconText>
              </IconsCards>
            ))}
          </IconsContainer>
        </CardsContainer>

        <CardsContainer background="#271F3D">
          <Title style={{ color: "var(--white)" }}>
            Como participo do{" "}
            <span style={{ color: "var(--primary)" }}>Clube The Best</span>?
          </Title>
          <ImgContainer>
            {appImages.map((cards) => (
              <>
                <IconsCards key={cards.id}>
                  <Imgs src={cards.icon} alt={cards.alt} />
                  <ImgTitle>{cards.title}</ImgTitle>
                  <ImgText>{cards.text}</ImgText>
                </IconsCards>
                {cards.id < 3 ? (
                  <IconArrowContainer>
                    <ImgsArrow src={Arrow} alt="Setas de fluxo"></ImgsArrow>
                  </IconArrowContainer>
                ) : (
                  <></>
                )}
              </>
            ))}
          </ImgContainer>
          <ButtonClub onClick={() => setItemModalOpen(true)}>
            Baixe o app e participe!
          </ButtonClub>
        </CardsContainer>

        <CardsContainer background="#F6A000">
          <ContentItems>
            <ContentPrize>
              <Title
                style={{ color: "var(--white)" }}
                className="clubTheBestTitle"
              >
                FIQUE LIGADO <br />
                NO PRAZO!
              </Title>

              <span>
                Seus pontos são válidos por 3 meses, se não utilizados dentro do
                prazo irão expirar de sua conta. Você pode resgatar sua
                recompensa na hora!
              </span>
            </ContentPrize>

            <img src={Items} alt="Itens the best clube" />
          </ContentItems>
        </CardsContainer>

        <QuestionsContainer>
          <Title>
            <h1>Perguntas Frequentes</h1>
            <ContentInput>
              <InputFind
                placeholder="Pesquisar"
                onChange={({ target: { value } }) => setSearch(value)}
              />
              <IconSearch />
            </ContentInput>
          </Title>

          {questions
            .filter((question) =>
              findQuestion(search).some(
                (searchedQuestion) => searchedQuestion.id === question.id
              )
            )
            .map((questions) => (
              <QuestionsCards
                key={questions.id}
                active={questionActive[questions.id]}
                onClick={() => handleOnChange(questions.id)}
              >
                <QuestionsTitleContainer>
                  <QuestionsTitle>{questions.title}</QuestionsTitle>
                  <QuestionIconContent active={questionActive[questions.id]}>
                    <QuestionIcon
                      alt="Setas"
                      src={
                        questionActive[questions.id]
                          ? questionClose
                          : questionOpen
                      }
                    />
                  </QuestionIconContent>
                </QuestionsTitleContainer>
                <QuestionsText active={questionActive[questions.id]}>
                  {questions.text}
                </QuestionsText>
              </QuestionsCards>
            ))}
        </QuestionsContainer>

        <ModalApp
          itemModalOpen={itemModalOpen}
          setItemModalOpen={setItemModalOpen}
        />
      </Container>

      <Footer active={true} />
    </>
  );
};
