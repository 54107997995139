import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import axios from "axios";

import apiJanus from "../../services/apiJanus";

import location from "../../assets/Icons/location.svg";

import allBrazilStates from "../../helpers/allBrazilStates.json";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { Spinner } from "../../components/Spinner";
import { BannerHome } from "../../components/Banners";

import { images_stores } from "./utils/images";
import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndLowerCase";

import {
  BoxLeftContent,
  ButtonLocation,
  Container,
  ContentInfo,
  ContentStores,
  ContentStoresState,
  CountryBox,
  DefaultImage,
  SectionSearch,
  StoreBox,
  StoreBoxState,
  StoreImage,
  TopContent,
} from "./styles";

interface Store {
  id: number;
  company_name: string;
  state_registration: string;
  city: string;
  address: string;
  district: string;
  company_status: boolean;
  ifood_url: string;
  description_delivery_plataform: string;
  description_delivery_time: string;
  description_store_time: string;
  whatsapp_number: string;
  complement: string;
  number: string;
  telefone: string;
  cep: string;
  image: string;
}

type City = {
  name: string;
  stores: [
    {
      id: number;
      name: string;
    }
  ];
};

type State = {
  name: string;
  state_registration: string;
  citys: City[];
};

type Country = {
  name: string;
  state: State[];
};

export const Lojas = () => {
  const _location = useLocation();
  const { cityLocation, stateLocation, locationSearch } = _location.state || {};

  const [loading, setLoading] = useState(false);
  const [countrys, setCountrys] = useState<Country[]>([]);
  const [selectState, setSelectState] = useState<State | null>(null);
  const [selectCity, setSelectCity] = useState<string>("");
  const [stores, setStores] = useState<Store[]>([]);

  useEffect(() => {
    const fetchStatesAndStores = async () => {
      try {
        setLoading(true);
        const {
          data: { content: content },
        } = await apiJanus.get("/company/amabest-club");

        const _companiesActives = content.filter(
          (_company: any) => _company.company_status && _company.country
        );

        let _countrys: Country[] = [];

        _companiesActives.forEach((_company: any) => {
          let country = _countrys.find(
            (_country) => _country.name === _company.country
          );

          if (!country) {
            country = {
              name: _company.country,
              state: [
                {
                  name:
                    allBrazilStates.find(
                      (_state) => _state.sigla === _company.state_registration
                    )?.nome || "",
                  state_registration: _company.state_registration,
                  citys: [
                    {
                      name: _company.city,
                      stores: [
                        { id: _company.id, name: _company.company_name },
                      ],
                    },
                  ],
                },
              ],
            };
            _countrys.push(country);
          } else {
            let state = country.state.find(
              (_state) =>
                _state.state_registration === _company.state_registration
            );

            if (!state) {
              state = {
                name:
                  allBrazilStates.find(
                    (_state) => _state.sigla === _company.state_registration
                  )?.nome || "",
                state_registration: _company.state_registration,
                citys: [
                  {
                    name: _company.city,
                    stores: [{ id: _company.id, name: _company.company_name }],
                  },
                ],
              };
              country.state.push(state);
            } else {
              let city = state.citys.find(
                (_city) => _city.name === _company.city
              );

              if (!city) {
                city = {
                  name: _company.city,
                  stores: [{ id: _company.id, name: _company.company_name }],
                };
                state.citys.push(city);
              } else if (
                !city.stores.some((_state) => _state.id === _company.id)
              ) {
                city.stores.push({
                  id: _company.id,
                  name: _company.company_name,
                });
              }
            }
          }
        });
        setCountrys(_countrys);

        if (stateLocation) {
          const _stateFind =
            _countrys
              ?.flatMap((_country) => _country?.state)
              ?.find(
                (_state) =>
                  removeAccentsAndLowerCase(_state?.state_registration) ===
                  stateLocation
              ) || null;
          if (_stateFind) {
            setSelectState(_stateFind);
          }
        }
        if (cityLocation) {
          setSelectCity(cityLocation);
        }

        if (locationSearch) {
          const _findLocationState =
            _countrys
              ?.flatMap((_country) => _country?.state)
              ?.find(
                (_state) =>
                  removeAccentsAndLowerCase(_state?.name) === locationSearch
              ) || null;

          if (_findLocationState) {
            setSelectState(_findLocationState);
          } else {
            const _findLocationCityState =
              _countrys
                ?.flatMap((_country) => _country?.state)
                ?.find((_state) =>
                  _state?.citys.some(
                    (_city) =>
                      removeAccentsAndLowerCase(_city?.name) === locationSearch
                  )
                ) || null;

            if (_findLocationCityState) {
              setSelectState(_findLocationCityState);
              const _findCityLocation = _findLocationCityState?.citys?.find(
                (_city) =>
                  removeAccentsAndLowerCase(_city?.name) === locationSearch
              )?.name;

              if (_findCityLocation) {
                setSelectCity(_findCityLocation);
              }
            }
          }
        }
      } catch {
        Swal.fire({
          title: "Ocorreu um erro!",
          text: "Parece que as cidades não estão disponíveis no momento.",
          timer: 4000,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchStatesAndStores();
  }, []);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true);

        const { data } = await apiJanus.get(
          `company/amabest-club?city=${selectCity}`
        );

        const activeStores = data.content.filter(
          (store: Store) => store.company_status === true
        );

        setStores(activeStores);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro ao carregar lojas",
          text: `Não foi possível carregar as lojas da cidade ${selectCity?.toUpperCase()}. Tente novamente mais tarde.`,
        });
      } finally {
        setLoading(false);
      }
    };

    if (selectCity) {
      fetchStores();
    }
  }, [selectCity]);

  const getLocation = async () => {
    try {
      const { data } = await axios.get("https://ipapi.co/json");
      const { city, region_code } = data;

      if (city && region_code) {
        const _stateFind =
          countrys
            ?.flatMap((_country) => _country?.state)
            ?.find(
              (_state) =>
                removeAccentsAndLowerCase(_state?.state_registration) ===
                removeAccentsAndLowerCase(region_code)
            ) || null;
        setSelectState(_stateFind);
        setSelectCity(removeAccentsAndLowerCase(city));
      } else {
        Swal.fire("Não foi possível identificar sua cidade e estado.");
      }
    } catch (error) {
      console.error("Erro ao obter localização:", error);
      Swal.fire("Não foi possível obter sua localização.");
    }
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Nossas Lojas</title>
        <link rel="canonical" href={`https://thebestacai.com.br/lojas`} />
        <meta property="og:image" content={images_stores.banner_home} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={images_stores.banner_home_mobile} />
      </Helmet>
      <Navbar />
      <BannerHome
        img={images_stores.banner_home}
        alt="Banner Nossas Lojas"
        text={false}
        imgMobile={images_stores.banner_home_mobile}
      />
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <TopContent>
              <SectionSearch>
                {!selectState ? (
                  <>
                    <h1 onClick={() => console.log(selectState)}>
                      Nossas Lojas
                    </h1>
                    <ButtonLocation onClick={getLocation}>
                      <img src={location} alt="Icone de Localização" />
                      Localizar loja mais próxima
                    </ButtonLocation>
                  </>
                ) : (
                  <>
                    <span className="selectsStateCity">
                      <span
                        className="span-hover"
                        onClick={() => {
                          setSelectCity("");
                          setSelectState(null);
                        }}
                      >
                        Nossas lojas
                      </span>{" "}
                      {" > "}{" "}
                      <span
                        className="span-hover"
                        onClick={() => setSelectCity("")}
                      >
                        {selectState.name}
                      </span>
                      <span> {selectCity ? " > " + selectCity : ""}</span>
                    </span>
                  </>
                )}
              </SectionSearch>
            </TopContent>

            {!selectState && !selectCity ? (
              countrys.map((_country) => (
                <CountryBox>
                  <span className="title">{_country.name}</span>
                  <ContentStoresState>
                    {_country.state.map((_state) => (
                      <StoreBoxState
                        key={_state.state_registration}
                        onClick={() => {
                          setSelectState(
                            _country.name === "paraguai"
                              ? { ..._state, name: "Paraguai" }
                              : _state
                          );
                        }}
                      >
                        {_country.name === "paraguai" ? (
                          <>
                            <h4>Paraguai</h4>
                            <p>{_state.citys.length} lojas</p>
                          </>
                        ) : (
                          <>
                            <h4>
                              {_state.name} - ({_state.state_registration})
                            </h4>
                            <p>{_state.citys.length} lojas</p>
                          </>
                        )}
                      </StoreBoxState>
                    ))}
                  </ContentStoresState>
                </CountryBox>
              ))
            ) : (
              <>
                {!selectCity ? (
                  <ContentStoresState>
                    {selectState?.citys.map((_city) => (
                      <StoreBoxState
                        key={_city.name}
                        onClick={() => setSelectCity(_city.name)}
                      >
                        <h4>{_city.name}</h4>
                        <p>{_city.stores.length} lojas</p>
                      </StoreBoxState>
                    ))}
                  </ContentStoresState>
                ) : (
                  <ContentStores>
                    {stores.map((store) => (
                      <StoreBox key={store.id}>
                        {!store?.image ? (
                          <DefaultImage
                            src={images_stores.banner_default_store}
                            alt="Imagem ilustrativa de uma loja"
                          />
                        ) : (
                          <StoreImage
                            src={store?.image}
                            alt="Imagem das lojas"
                          />
                        )}
                        <BoxLeftContent>
                          <h4>
                            {store?.address}, {store?.number} -{" "}
                            {store?.district}{" "}
                            {store?.complement ? "- " + store?.complement : ""}
                          </h4>
                          <ContentInfo>
                            <h6>
                              ⏰ <strong>Horário:</strong>{" "}
                              {store.description_store_time || "Não informado"}
                            </h6>
                            <h6>
                              🛵 <strong>Delivery:</strong>{" "}
                              {store.description_delivery_time ||
                                "Não informado"}
                            </h6>
                            <h6>
                              📱 <strong>Plataformas:</strong>{" "}
                              {store.description_delivery_plataform ||
                                "Não informado"}
                            </h6>
                            <h6>
                              📞 <strong>Telefone:</strong>{" "}
                              {store.telefone
                                ? `(${store.telefone.slice(
                                    0,
                                    2
                                  )}) ${store.telefone.slice(
                                    2,
                                    7
                                  )}-${store.telefone.slice(7)}`
                                : "Não informado"}
                            </h6>
                            {store.whatsapp_number && (
                              <h6>
                                📞 <strong>Whatsapp:</strong>{" "}
                                <a
                                  href={`https://api.whatsapp.com/send?phone=55${store.whatsapp_number}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  ({store.whatsapp_number.slice(0, 2)}){" "}
                                  {store.whatsapp_number.slice(2, 7)} -{" "}
                                  {store.whatsapp_number.slice(7, 11)}
                                </a>
                              </h6>
                            )}
                          </ContentInfo>

                          {/* <Link to={`/lojas/${store.state_registration}/${store.city}/${store.id}`}>
                                        Ver Mais
                                      </Link> */}
                        </BoxLeftContent>
                      </StoreBox>
                    ))}
                  </ContentStores>
                )}
              </>
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};
