import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  html{
    scroll-behavior: smooth;
    max-width: 100%;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'Visby';
    src: url('../fonts/VISBYCF-BOLD.OTF');
    src: url('../../font/VISBYCF-REGULAR.OTF');

    src: url('../fonts/VISBYCF-EXTRABOLD.OTF');
    src: url('../fonts/VISBYCF-HEAVY.OTF');

    src: url('../fonts/VISBYCF-THIN.OTF');

    font-display: swap
  }
  @font-face {
    font-family: 'Visby-extrabold';
    src: url('../fonts/VISBYCF-EXTRABOLD.OTF');

    font-display: swap
  }
  @font-face {
    font-family: 'Visby-bold';
    src: url('../fonts/VISBYCF-HEAVY.OTF');

    font-display: swap
  }
  @font-face {
    font-family: 'Visby-demi-bold';
    src: url('../fonts/VISBYCF-DEMIBOLD.OTF');

    font-display: swap
  }
  @font-face {
    font-family: 'Visby-regular';
    src: url('../fonts/VISBYCF-REGULAR.OTF');

    font-display: swap
  }
  @font-face {
    font-family: 'Visby-light';
    src: url('../fonts/VISBYCF-LIGHT.OTF');

    font-display: swap
  }
  @font-face {
    font-family: 'Visby-medium';
    src: url('../fonts/VISBYCF-MEDIUM.OTF');

    font-display: swap
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

  }

  body {
    background: #fff;
    font-family: 'Visby', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1 {
    font-size: 3.4rem;
    font-family: 'Visby-demi-bold';
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1500px) {
      font-size: 2.8rem;
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 1300px) {
      font-size: 2.3rem;
    }

  }
  h2{
    font-size: 2.5rem;
  }
  h3{
    font-size: 2.5rem;
    font-family: 'Visby-demi-bold';
    margin-bottom:2.5rem;
    @media screen and (max-width: 768px){
      font-size: 1.4rem;
      margin-bottom:1rem;
    }
  }
  p{
    font-size: 1.5rem;
    font-family: 'Visby-regular';
    letter-spacing: 0.2rem;
    line-height: 1.9rem;

    
    @media screen and (max-width: 1700px) {
      font-size: 1.7rem;
    }
    @media screen and (max-width: 1500px) {
      font-size: 1.2rem;
    letter-spacing: 0.125rem;
    }
  }

.swal2-html-container{
  font-weight: 700;
}
.swal2-title{
  font-family: 'Visby-demi-bold';
}

  .rec-dot{
    margin: 3rem .4rem;
  }

  .rec .rec-dot{
    box-shadow: 0 0 1px 1px rgb(103 58 183 / 50%);
  }

  .rec-dot_active{
    background-color: var(--primary);
    box-shadow: var(--primary);
  }
  .swal2-confirm  {
    background-color: var(--primary) !important;
  }



span{
  font-family: 'Visby-regular';
}


:root{
  --white: #fff;
  --white-100: #F5EBDC;
  --gray-10: #fafafa;
  --gray-25: #e0e1e3;
  --gray-35: #d0d0ce;
  --gray-35-40: #d0d0ce40;
  --gray-45: #A3A3A3;
  --gray-50: #E8E8E8;
  --gray-75: #BABABA;
  --gray-88: #888888;
  --gray-89: #898B8E;
  --gray-95: #757575; 
  --gray-100: #53565A;
  --gray-150: #5A5A5A;
  --gray-180: #444444;
  --primary: #F6A000;
  --primary-40: #F6A00040;
  --yellow-10: #F69F20;
  --yellow-20: #f9a940;
  --black: #000;
  --black-20: #0a0a0a;
}
`;

export const ContainerWidth = styled.div`
  padding: 3.125rem 8rem;

  @media screen and (max-width: 1500px) {
    padding: 3rem 4rem;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 4rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem;
    position: relative;
    overflow: hidden;
  }
`;

export const ContainerHome = styled.div`
  padding: 3rem 13.5rem;

  @media screen and (max-width: 1200px) {
    padding: 3rem 8rem;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 4rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 8rem;
    position: relative;
    overflow: hidden;
  }
  @media screen and (max-width: 700px) {
    padding: 3rem 2rem;
    position: relative;
    overflow: hidden;
  }
`;

export default GlobalStyle;
