import React, { useState } from "react";

import { FaBars, FaTimes } from "react-icons/fa";

import logo_social from "../../assets/svg/logo_social.svg";
import instagram from "../../assets/svg/instagram.svg";
import twitter from "../../assets/svg/twitter.svg";
import tiktok from "../../assets/svg/tiktok.svg";
import facebook from "../../assets/svg/facebook.svg";

import {
  Container,
  ListOfLinks,
  Logo,
  MobileIcon,
  Nav,
  NavItem,
  NavLinks,
  SocialIcon,
  SocialMenu,
} from "./styles";

export const Navbar: React.FC = () => {
  const LogoNav = require("../../assets/Images/logo_nav.webp");

  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  return (
    <Container>
      <Nav>
        <Logo
          src={LogoNav}
          alt="Logo do The best açaí"
          onClick={() => (window.location.href = "/")}
        />

        <ListOfLinks open={click} onClick={handleClick}>
          <NavItem>
            <NavLinks to="/">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>Início</span>
              )}
            </NavLinks>
            <NavLinks to="/sobre">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Nossa história
                </span>
              )}
            </NavLinks>
            <NavLinks to="/lojas">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Nossas lojas
                </span>
              )}
            </NavLinks>
            <NavLinks to="/blog">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>Blog</span>
              )}
            </NavLinks>
            <NavLinks to="/trabalhe-conosco">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Trabalhe conosco
                </span>
              )}
            </NavLinks>
            <NavLinks to="/thebest-club">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Clube The Best
                </span>
              )}
            </NavLinks>
            <a
              className="buttonFranchisee"
              target="_blank"
              rel="noreferrer"
              href="https://franquia.thebestacai.com.br/sejaumfranqueado
 "
            >
              Seja um Franqueado
            </a>
          </NavItem>
        </ListOfLinks>

        <MobileIcon onClick={handleClick}>
          {click ? (
            <FaTimes color="var(--white)" />
          ) : (
            <FaBars color="var(--white)" />
          )}
        </MobileIcon>
      </Nav>
      <SocialMenu>
        <SocialIcon src={logo_social} alt="Logo da marca" />
        <a
          href="https://www.facebook.com/thebestacaiofficial/?locale=pt_BR"
          target="_blank"
          rel="noreferrer"
          aria-hidden="true"
        >
          <SocialIcon src={facebook} alt="Link para Faceboom" />
        </a>
        <a
          href="https://www.tiktok.com/@thebestacaiofficial"
          target="_blank"
          rel="noreferrer"
          aria-hidden="true"
        >
          <SocialIcon src={tiktok} alt="Link para TikTok" />
        </a>
        <a
          href="https://www.instagram.com/thebestacaiofficial/"
          target="_blank"
          rel="noreferrer"
          aria-hidden="true"
        >
          <SocialIcon src={instagram} alt="Link para Instagram" />
        </a>
        <a
          href="https://twitter.com/thebest_acai"
          target="_blank"
          rel="noreferrer"
          aria-hidden="true"
        >
          <SocialIcon src={twitter} alt="Link para X" />
        </a>
      </SocialMenu>
    </Container>
  );
};
