import { useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import Swal from "sweetalert2";

import anexo from "../../assets/svg/anexo.svg";

import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import { Spinner } from "../../components/Spinner";

import apiEmail from "../../services/apiEmail";

import { onlyNumbers } from "../../utils/onlyNumbers";

import {
  ButtonSave,
  CheckBox,
  CheckBoxContainer,
  CheckBoxLabel,
  Container,
  ContentForm,
  FormItem,
  Input,
  InputUpload,
  Label,
  TextArea,
  TopContent,
  UploadContainer,
  FileList,
} from "./styles";

interface IFormValues {
  nome: string;
  emitente_razao_social: string;
  emitente_nome_fantasia: string;
  cnpj: string;
  telefone: string;
  email: string;
  cep: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  estado: string;
  observacao: string;
}

export const SupplierForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [serviceProduct, setServiceProduct] = useState<"service" | "product">(
    "service"
  );
  const [formValues, setFormValues] = useState<IFormValues>({
    nome: "",
    emitente_razao_social: "",
    emitente_nome_fantasia: "",
    cnpj: "",
    telefone: "",
    email: "",
    cep: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    observacao: "",
  });

  const resetFields = async () => {
    setFormValues({
      nome: "",
      emitente_razao_social: "",
      emitente_nome_fantasia: "",
      cnpj: "",
      telefone: "",
      email: "",
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      observacao: "",
    });
    setFiles([]);
    //@ts-ignore
    document.getElementById("input_file").value = "";
  };

  const onChangeFormValues = (name: string, value: string) => {
    setFormValues((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  };

  const onUploadFiles = async (uploadedFiles: FileList) => {
    const newFileList = Array.from(uploadedFiles);
    const maxSize = 25 * 1024 * 1024;

    if (files.length + newFileList.length > 5) {
      return Swal.fire({
        title: `Quantidade arquivos ultrapassada`,
        text: "É possível adicionar no máximo 5 arquivos",
      });
    }

    const _fileList: File[] = [];

    newFileList.forEach((file) => {
      const isAlreadyAdded = files.some((_file) => _file.name === file.name);
      if (file.size > maxSize) {
        return Swal.fire({
          title: `O arquivo ${file.name} ultrapassa o limite de 5mb`,
        });
      }
      if (!isAlreadyAdded) {
        _fileList.push(file);
      }
    });

    if (_fileList.length > 0) {
      setFiles((oldValues) => [...oldValues, ..._fileList]);
      //@ts-ignore
      document.getElementById("input_file").value = "";
    }
  };

  const removeFile = async (index: number) => {
    const _findFile = files[index];

    setFiles(() => files.filter((_file) => _file.name !== _findFile.name));
  };

  const handleChangeCep = async (cep: string) => {
    const _cep = onlyNumbers(cep)?.toString();
    if (_cep?.length === 8) {
      const { data } = await axios({
        url: `https://viacep.com.br/ws/${_cep}/json/`,
        method: "GET",
      });

      setFormValues((oldValues) => ({
        ...oldValues,
        estado: data.estado,
        cidade: data.localidade,
        endereco: data.logradouro,
        bairro: data.bairro,
      }));
    }
  };

  const onFinish = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if (!accept) {
      return Swal.fire({
        title: "Aceite os termos de privacidade",
        text: "É necessário aceitar os termos de privacidade para prosseguir com o envio",
      });
    }
    if (!files.length) {
      return Swal.fire({
        title: "Adicione um arquivo",
        text: "Adicione pelo menos um arquivo de no máximo 25mb",
      });
    }
    
    const formData = new FormData();
    
    formData.append("bairro", formValues.bairro);
    formData.append("cep", onlyNumbers(formValues.cep || "")?.toString() || "");
    formData.append("cidade", formValues.cidade);
    formData.append(
      "cnpj",
      onlyNumbers(formValues.cnpj || "")?.toString() || ""
    );
    formData.append("email", formValues.email);
    formData.append(
      "emitente_nome_fantasia",
      formValues.emitente_nome_fantasia
    );
    formData.append("emitente_razao_social", formValues.emitente_razao_social);
    formData.append("endereco", formValues.endereco);
    formData.append("estado", formValues.estado);
    formData.append("nome", formValues.nome);
    formData.append(
      "numero",
      onlyNumbers(formValues.numero || "")?.toString() || ""
    );
    formData.append("observacao", formValues.observacao);
    formData.append(
      "telefone",
      onlyNumbers(formValues.telefone || "")?.toString() || ""
    );
    formData.append(
      "tipo",
      serviceProduct === "service" ? "Serviço" : "Produto"
    );
    
    files.forEach(async (file: File) => {
      await formData.append("files", file);
    });

    try {
      setLoading(true);
      await apiEmail.post(`/emails/send-email-supplier-form`, formData);
      
      Swal.fire({
        title: "Enviado com sucesso",
        icon: "success",
      });
      await resetFields();
    } catch (e) {
      const _message =
      //@ts-ignore
        e.response.data.message || "Por favor tente novamente mais tarde";
      Swal.fire({
        title: "Ops! Algo deu errado",
        text: `${_message}`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Seja nosso Fornecedor</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/seja-nosso-fornecedor`}
        />
        <meta property="og:image" content={""} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={""} />
      </Helmet>
      <Navbar />
      <Container>
        <TopContent>
          <h1>
            Seja nosso <span>fornecedor</span>
          </h1>
        </TopContent>

        <ContentForm onSubmit={onFinish}>
          <div className="totalRow">
            <FormItem>
              <Label>
                Nome completo<span>*</span>
              </Label>
              <Input
                type="text"
                id="nome"
                name="nome"
                placeholder="Ex: João"
                required
                maxLength={70}
                value={formValues.nome}
                onChange={(event) =>
                  onChangeFormValues("nome", event?.target?.value)
                }
              />
            </FormItem>
          </div>
          <div className="partialRow">
            <FormItem>
              <Label>
                Razão social<span>*</span>
              </Label>
              <Input
                type="text"
                id="emitente_razao_social"
                name="emitente_razao_social"
                placeholder="Ex: The Best LTDA"
                required
                maxLength={70}
                value={formValues.emitente_razao_social}
                onChange={(event) =>
                  onChangeFormValues(
                    "emitente_razao_social",
                    event?.target?.value
                  )
                }
              />
            </FormItem>
            <FormItem>
              <Label>
                Nome fantasia<span>*</span>
              </Label>
              <Input
                type="text"
                id="emitente_nome_fantasia"
                name="emitente_nome_fantasia"
                placeholder="Ex: The Best Açaí"
                required
                maxLength={70}
                value={formValues.emitente_nome_fantasia}
                onChange={(event) =>
                  onChangeFormValues(
                    "emitente_nome_fantasia",
                    event?.target?.value
                  )
                }
              />
            </FormItem>
          </div>
          <div className="totalRow">
            <FormItem>
              <Label>
                CNPJ<span>*</span>
              </Label>
              <Input
                type="text"
                id="cnpj"
                name="cnpj"
                placeholder="00.000.000/0001-00"
                value={formValues.cnpj}
                onChange={(event) =>
                  onChangeFormValues(
                    "cnpj",
                    event?.target?.value
                      ?.replace(/\D/g, "")
                      ?.replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        "$1.$2.$3/$4-$5"
                      )
                  )
                }
                required
                maxLength={18}
                minLength={14}
              />
            </FormItem>
          </div>
          <div className="partialRow">
            <FormItem>
              <Label>
                Contato<span>*</span>
              </Label>
              <Input
                type="text"
                id="telefone"
                name="telefone"
                placeholder="+55 (43) 9 0000-0000"
                value={formValues.telefone}
                onChange={(event) =>
                  onChangeFormValues(
                    "telefone",
                    event?.target?.value
                      ?.replace(/\D/g, "")
                      ?.replace(
                        /^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/,
                        "+$1 ($2) $3 $4-$5"
                      )
                  )
                }
                required
                maxLength={18}
                minLength={14}
              />
            </FormItem>
            <FormItem>
              <Label>
                E-mail<span>*</span>
              </Label>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="thebest@exemplo.com"
                required
                maxLength={50}
                value={formValues.email}
                onChange={(event) =>
                  onChangeFormValues("email", event?.target?.value)
                }
              />
            </FormItem>
          </div>
          <div className="totalRow">
            <FormItem>
              <Label>
                CEP<span>*</span>
              </Label>
              <Input
                type="text"
                id="cep"
                name="cep"
                placeholder="00000-000"
                value={formValues.cep}
                onChange={(event) => (
                  onChangeFormValues(
                    "cep",
                    event?.target?.value
                      ?.replace(/\D/g, "")
                      ?.replace(/^(\d{5})(\d{3})/, "$1-$2")
                  ),
                  handleChangeCep(event?.target?.value)
                )}
                required
                minLength={8}
                maxLength={9}
              />
            </FormItem>
          </div>
          <div className="partialRow">
            <FormItem>
              <Label>
                Endereço<span>*</span>
              </Label>
              <Input
                type="text"
                id="endereco"
                name="endereco"
                placeholder="Rua com complemento"
                value={formValues.endereco}
                onChange={(event) =>
                  onChangeFormValues("endereco", event?.target?.value)
                }
                required
                maxLength={70}
              />
            </FormItem>
            <div className="partialRow">
              <FormItem>
                <Label>
                  Número<span>*</span>
                </Label>
                <Input
                  type="text"
                  id="numero"
                  name="numero"
                  placeholder="00"
                  value={formValues.numero}
                  onChange={(event) =>
                    onChangeFormValues(
                      "numero",
                      event?.target?.value?.replace(/\D/g, "")
                    )
                  }
                  required
                />
              </FormItem>
              <FormItem>
                <Label>
                  Bairro<span>*</span>
                </Label>
                <Input
                  type="text"
                  id="bairro"
                  name="bairro"
                  placeholder="Bairro"
                  value={formValues.bairro}
                  onChange={(event) =>
                    onChangeFormValues("bairro", event?.target?.value)
                  }
                  required
                  maxLength={70}
                />
              </FormItem>
            </div>
          </div>
          <div className="partialRow">
            <FormItem>
              <Label>
                Cidade<span>*</span>
              </Label>
              <Input
                type="text"
                id="cidade"
                name="cidade"
                placeholder="Cidade"
                value={formValues.cidade}
                onChange={(event) =>
                  onChangeFormValues("cidade", event?.target?.value)
                }
                required
                maxLength={50}
              />
            </FormItem>
            <FormItem>
              <Label>
                Estado<span>*</span>
              </Label>
              <Input
                type="text"
                id="estado"
                name="estado"
                placeholder="Estado"
                value={formValues.estado}
                onChange={(event) =>
                  onChangeFormValues("estado", event?.target?.value)
                }
                required
                maxLength={50}
              />
            </FormItem>
          </div>
          <div className="totalRow">
            <FormItem>
              <Label>
                Anotações<span>*</span>
              </Label>
              <TextArea
                name="observacao"
                id="observacao"
                rows={4}
                cols={50}
                placeholder="Se apresente aqui e insira anexos."
                maxLength={500}
                required
                value={formValues.observacao}
                onChange={(event) =>
                  onChangeFormValues("observacao", event?.target?.value)
                }
              />
            </FormItem>
          </div>
          <UploadContainer>
            <FileList>
              {files.map((_file, index) => (
                <div className="file">
                  <span>{_file.name}</span>
                  <span
                    className="delete-file"
                    onClick={() => removeFile(index)}
                  >
                    X
                  </span>
                </div>
              ))}
            </FileList>
            <InputUpload>
              <input
                type="file"
                className="file-input"
                id="input_file"
                accept=".xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf"
                maxLength={5}
                multiple
                onChange={(event) => {
                  const fileList = event.target.files;
                  if (fileList) {
                    onUploadFiles(fileList);
                  }
                }}
              />
              <img src={anexo} />
              Anexo
            </InputUpload>
          </UploadContainer>
          <div className="partialRow">
            <FormItem>
              <Label>
                Categoria<span>*</span>
              </Label>
              <CheckBoxContainer>
                <CheckBoxLabel onClick={() => setServiceProduct("service")}>
                  <CheckBox active={serviceProduct === "service"} />
                  <span>Serviços</span>
                </CheckBoxLabel>

                <CheckBoxLabel onClick={() => setServiceProduct("product")}>
                  <CheckBox active={serviceProduct === "product"} />
                  <span>Produtos</span>
                </CheckBoxLabel>
              </CheckBoxContainer>
            </FormItem>
            <FormItem>
              <Label>
                <span className="transparent">Termos</span>
              </Label>
              <CheckBoxLabel onClick={() => setAccept(!accept)}>
                <CheckBox active={accept} className="privacy-terms-div"/>
                <span className="privacy-terms">
                  Concordo com o Termo de Ciência à Política de Privacidade
                </span>
              </CheckBoxLabel>
            </FormItem>
          </div>
          <div className="totalRow">
            <ButtonSave type="submit" disabled={loading}>
              {loading ? <Spinner /> : "Enviar"}
            </ButtonSave>
          </div>
        </ContentForm>
      </Container>
      <Footer />
    </>
  );
};
