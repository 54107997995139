import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: 11rem;
  height: 11rem;
  border-radius: 1.25rem;
  border: 2px solid var(--primary);
  margin: 1rem;

  transition: width 1s, opacity 2s;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    transition: height 1s, opacity 2s;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    width: 11rem;
    height: 11rem;

    img {
      width: 30%;
      height: 30%;
      border-radius: 50%;
    }

    p {
      color: var(--primary);
      font-family: "Visby-demi-bold";
      font-size: 1.2rem;

      @media only screen and (max-width: 500px) {
        margin: 0;
        font-size: 1rem;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0rem;

    p {
      display: none;
      color: var(--gray-100);
      font-family: "Visby-regular";
      font-size: 1.2rem;
      line-height: 1.5rem;
      @media only screen and (max-width: 500px) {
        margin: 0;
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }

    @media only screen and (max-width: 500px) {
      align-items: center;
      padding: 0rem;
    }
  }

  :hover {
    width: 30rem;
    transition: width 1s, opacity 3s;

    @media only screen and (max-width: 500px) {
      transition: height 1s, opacity 3s;
      width: 11rem;
      height: 23rem;
    }
  }

  :hover > .left {
    width: 11rem;
    transition: width 0s;

    @media only screen and (max-width: 500px) {
      height: 11rem;
      transition: height 0s;
    }
  }

  :hover > .right {
    transition: width 1s, opacity 3s, padding 1s;
    width: 19rem;
    height: 11rem;
    opacity: 1;
    padding: 1rem;

    p {
      display: block;
    }

    @media only screen and (max-width: 500px) {
      justify-content: start;
      transition: height 1s, opacity 3s, padding 1s;
      width: 11rem;
      height: 12rem;
      padding: 0.3rem;
    }
  }
`;
