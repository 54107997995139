import React from "react";
import { MainContainer } from "./styles";

interface IBoxProps {
  img?: any;
  alt?: string;
  text: React.ReactNode;
  back: React.ReactNode;
  title: React.ReactNode;
}

export const Box: React.FC<IBoxProps> = ({ img, text, alt, back, title }) => {
  return (
    <>
      <MainContainer>
        <div className="left">
          <img src={img} alt={alt} />
          <p>{text}</p>
        </div>
        <div className="right">
          <p>{back}</p>
        </div>
      </MainContainer>
    </>
  );
};
