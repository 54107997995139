import React from "react";
import { ContainerImg, ContentLinks, Img, ImgMobile } from "./styles";

interface IBannerProps {
  img: string;
  imgMobile: string;
  alt: string;
  text?: boolean;
  page?: string;
  onClickEvent?: () => void;
  children?: React.ReactNode;
}

export const BannerHome: React.FC<IBannerProps> = ({
  img,
  imgMobile,
  alt,
  text,
  children,
}) => {
  const AndroidLink = require("../../assets/Images/android.webp");
  const AppleLink = require("../../assets/Images/apple.webp");

  return (
    <ContainerImg>
      <Img src={img} alt={alt} />
      <ImgMobile src={imgMobile} alt={alt} />
      {text && (
        <ContentLinks>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.amatech.thebestclubapp"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img
                src={AndroidLink}
                alt="Link app googleplay"
                aria-hidden="true"
              />
            </a>{" "}
            <a
              href="https://apps.apple.com/br/app/clube-the-best/id6463561926"
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
            >
              <img
                src={AppleLink}
                alt="Link app apple store"
                aria-hidden="true"
              />
            </a>
          </div>
        </ContentLinks>
      )}
      {children}
    </ContainerImg>
  );
};
