import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border: 2px solid var(--primary);
  border-radius: 1.2rem;
  box-shadow: 10px 5px 1rem var(--gray-35-40);
  margin: 2rem 0;
  padding: 3rem 5rem;

  @media only screen and (max-width: 1200px) {
    margin: 1rem 0;
    padding: 1.5rem 2.5rem;
  }

  @media only screen and (max-width: 500px) {
    margin: 0.3rem 0;
    padding: 0.2rem 0.5rem;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  h1 {
    margin: 0;
    font-family: "Visby-bold";
    font-size: 3rem;
    color: var(--primary);
    text-transform: capitalize;
    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 0.2rem;
  }
`;
export const OpenCloseInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 5rem;
  background: var(--primary);

  cursor: pointer;

  @media only screen and (max-width: 500px) {
    width: 2.75rem;
    height: 2.75rem;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const FlavorNameTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  background: var(--primary);
  border-radius: 5rem;
  color: var(--white);
  font-size: 1.2rem;
  font-family: "Visby-demi-bold";

  @media only screen and (max-width: 500px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  span {
    font-size: 1.2rem;
    line-height: 1.9rem;

    @media only screen and (max-width: 500px) {
      font-size: 0.9rem;
      line-height: 1.3rem;
    }
  }

  .bold {
    font-family: "Visby-demi-bold";
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const NutritionalTableContainer = styled.div`
  margin: 0 1rem 1rem 0;
  .table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0;
    font-size: 1.2rem;
    font-family: "Visby-bold";

    @media only screen and (max-width: 1200px) {
      font-size: 1rem;
    }
    @media only screen and (max-width: 500px) {
      font-size: 0.8rem;
    }
  }
  .table-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--primary);
    padding: 0.3rem 0;
    font-size: 1.2rem;
    font-family: "Visby-regular";
    @media only screen and (max-width: 1200px) {
      font-size: 1rem;
    }
    @media only screen and (max-width: 500px) {
      font-size: 0.8rem;
    }
  }
  .name {
    display: flex;
    justify-content: start;
    width: 100%;
  }
  .div-values {
    display: flex;
    flex-direction: row;
  }
  .value {
    display: flex;
    justify-content: center;
    width: 4rem;
    margin: 0 0.5rem;
    @media only screen and (max-width: 500px) {
      width: 2rem;
    }
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  span {
    color: var(--gray-100);
    font-size: 1.2rem;
    line-height: 1.3rem;
    max-height: 15rem;
    overflow-y: auto;
    @media only screen and (max-width: 500px) {
      font-size: 0.9rem;
      max-height: 11rem;
    }
  }

  .bold {
    color: var(--black);
    font-family: "Visby-demi-bold";
  }
  .regular {
    font-family: "Visby-regular";
  }

  .uppercase-bold {
    color: var(--black);
    font-family: "Visby-demi-bold";
    text-transform: uppercase;
  }

  .uppercase-regular {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 1rem;
  }
`;

export const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    font-size: 3rem;
  }
`;

export const TagFlavor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Visby-demi-bold";
  padding: 0.2rem 0.5rem;

  border: 2px solid var(--primary);
  border-radius: 2rem;
  p {
    width: 75%;
    color: var(--gray-100);
    font-family: "Visby-demi-bold";
    font-size: 1rem;
    text-transform: capitalize;
    @media only screen and (max-width: 500px) {
      font-size: 0.8rem;
    }
  }
  img {
    width: 2rem;
    height: 2rem;
  }
`;
