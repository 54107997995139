import styled, { css } from "styled-components";
import { Instagram } from "../../styles/icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

interface IProps {
  img?: string;
}
export const FooterContainer = styled.div<IProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 28rem;
  padding: 3.5rem 8rem 2rem;
  overflow: hidden;

  @media screen and (max-width: 1400px) {
    padding: 2.5rem 4rem 3.5rem;
  }
  @media screen and (max-width: 1100px) {
    padding: 1rem 4rem 2rem;
  }
  @media screen and (max-width: 600px) {
    height: 31rem;
    padding: 0.5rem 2rem 0rem;
  }

  ${({ img }) => {
    if (img) {
      return css`
        background: url("${img}");
        background-repeat: no-repeat;
        background-size: cover;
      `;
    } else {
      return css`
        background-color: var(--black);
      `;
    }
  }}
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  .logofooter {
    width: 100%;
    height: 3rem;

    @media only screen and (max-width: 500px) {
      height: 1rem;
    }
  }

  .spanInfo {
    color: var(--black);
    font-size: 0.8rem;
    text-align: justify;

    @media only screen and (max-width: 500px) {
      font-size: 0.7rem;
    }
  }

  .img {
    margin: 0 0.5rem;
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  width: 90%;
  margin-top: 2.5rem;

  a {
    font-family: "Visby-regular";
  }

  @media screen and (max-width: 1024px) {
    align-items: center;
  }

  p,
  a {
    cursor: pointer;
    color: var(--white);
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0;
    text-decoration: none;

    @media screen and (max-width: 1600px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.9rem;
    }

    &:hover {
      display: inline-block;
      position: relative;
      color: var(--black);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--black);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  p + p,
  p + a {
    margin-left: 9rem;

    @media screen and (max-width: 1600px) {
      margin-left: 4rem;
    }
    @media screen and (max-width: 1200px) {
      margin-left: 2rem;
    }
    @media screen and (max-width: 1100px) {
      margin-left: 0;
    }
  }
`;

export const Line = styled.hr`
  border-bottom: 2px solid var(--black);
  width: 100%;
  margin: 1.5rem 0;
`;

export const Regulamentation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 0.5rem;
  }

  a {
    cursor: pointer;
    color: var(--black);
    font-size: 1rem;
    letter-spacing: 0rem;

    &:hover {
      display: inline-block;
      position: relative;
      color: var(--black);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--black);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.9rem;
    }
  }
`;
export const Selo = styled.img`
  margin-top: 0.5rem;
  height: 7rem;

  @media screen and (max-width: 1100px) {
    height: 5rem;
  }
  @media screen and (max-width: 425px) {
    height: 3.5rem;
  }
  @media screen and (max-width: 400px) {
    height: 3rem;
  }
`;

interface IProps {
  active?: boolean;
}

export const ButtonBox = styled.div<IProps>`
  ${({ active }) => {
    if (active) {
      return css`
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 5rem;

        @media screen and (max-width: 1100px) {
          width: 70%;
        }
        @media screen and (max-width: 680px) {
          width: 80%;
        }
        @media screen and (max-width: 575px) {
          padding: 1rem;
        }
        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: center;
        }
      `;
    } else {
      return css`
        display: none;
      `;
    }
  }}
`;

export const ButtonBoxButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 25rem;
  height: 4.1rem;

  background: var(--primary);

  font-family: "Visby-bold";
  font-size: 1.875rem;
  font-weight: 700;

  box-shadow: none;
  border: none;

  border-radius: 2rem;

  a {
    text-decoration: none;
    color: var(--black);
  }

  :active,
  ::selection {
    box-shadow: none;
    border: none;
  }
  :hover {
    cursor: pointer;
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1490px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 1210px) {
    font-size: 1.1rem;
    width: 14rem;
  }
  @media screen and (max-width: 940px) {
    font-size: 0.9rem;
    width: 10rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 0.8rem;
    width: 9rem;
  }
  @media screen and (max-width: 500px) {
    text-align: center;
    width: 10rem;
  }
`;

export const SpanCnpj = styled.span`
  color: var(--black);
  font-size: 1.125rem;
  letter-spacing: 0rem;

  @media screen and (max-width: 500px) {
    font-size: 0.7rem;
  }
`;

export const Imprensa = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 0.3rem;

  @media screen and (max-width: 1024px) {
    justify-content: center;
    align-items: center;
  }

  span {
    color: var(--black);
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0rem;
  }
`;

export const ImgInstagram = styled(Instagram)`
  width: 25px;
  height: 25px;
  fill: white;
`;
