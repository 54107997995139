import gluten from "../../../assets/svg/gluten.svg"
import sem_gluten from "../../../assets/svg/sem_gluten.svg"
import nozes from "../../../assets/svg/nozes.svg"
import lactose from "../../../assets/svg/lactose.svg"
import sem_lactose from "../../../assets/svg/sem_lactose.svg"

export const getItemTag = (tag: string): string => {
    const lactoseTags = ["lactose", "leite"];
    const nozesTags = ["amendoim", "avelã", "castanha", "castanha de caju", "macadâmia", "pistache", "nozes"];
    const glutenTags = ["aveia", "centeio", "cevada", "glúten", "trigo", "triticale"];
    
    if (glutenTags.includes(tag.toLowerCase())) {
        return gluten;
    }
    if (lactoseTags.includes(tag.toLowerCase())) {
        return lactose;
    }
    if (nozesTags.includes(tag.toLowerCase())) {
        return nozes;
    }
    if (tag.toLowerCase() === "sem-glúten") {
        return sem_gluten;
    }
    if (tag.toLowerCase() === "sem-lactose") {
        return sem_lactose;
    }
    
    return gluten
}